import { KEYS, getStorageRef } from '~/services/storage'

/**
 * stored ref for guest role
 * undefined: not yet initialized (it should never happen after plugin execution)
 * true: Guest role
 * false: Engineering Team Member role
 */
export const isGuest = getStorageRef(KEYS.isGuest, (serialized) =>
  serialized === null ? undefined : (JSON.parse(serialized) as boolean),
)

export const toggleRole = () => {
  isGuest.value = !isGuest.value
  // reload the page to apply the new role
  window.location.reload()
}

export default defineNuxtPlugin(() => {
  // TODO: console logs to be removed!
  console.log('>>> isGuest', isGuest.value)
  if (isGuest.value === undefined) {
    isGuest.value = true
    console.log('>>> setting guest role!', isGuest.value)
  }
})
