import { sprintsHub } from '../SprintsHub'

class DomainVelocity {
  constructor(
    private domain: Domain,
    private includeCurrentSprint: boolean,
  ) {}
  private get devsVelocity() {
    const perf = this.includeCurrentSprint
      ? sprintsHub.devVelocityIncludingCurrent
      : sprintsHub.devVelocityNoCurrent
    return perf.filter((x) => x.domain === this.domain)
  }

  /**
   * average performance of the domain.
   * It is computed as the average of the performance of all devs in the domain.
   */
  get velocity(): number {
    return this.devsVelocity.avg((x) => {
      x.velocity
      return x.velocity
    })
  }
}

export const DomainVelocityR = reactifyClass(DomainVelocity)
