import dayjs, { Dayjs } from 'dayjs'

/**
 *
 * @param days
 * @returns
 */
export const getPastDate = (days: number, refDate?: Date) => {
  const date = refDate ? new Date(refDate) : new Date()
  date.setDate(date.getDate() - days)
  return date
}

/**
 * TODO: this should replace getPastDate
 */
export const sumDays = (days: number, refDate?: Date) => {
  const date = refDate ? new Date(refDate) : new Date()
  date.setDate(date.getDate() + days)
  return date
}

/**
 * @deprecated use dayjs extension instead (see utils/dates/plugins/index.ts)
 * @param date
 * @returns
 */
export const isPast = (date: Dayjs) => date.isBefore(dayjs().startOf('day'))

type ISOShortDate = string

export const getDayStart = (iSOShortDate: ISOShortDate) => {
  const d = new Date(iSOShortDate)
  d.setHours(0)
  return d
}

export const getDayDate = (iSOShortDate?: ISOShortDate) =>
  dayjs(iSOShortDate).startOf('day')

const SPENT_TIME_CATCHER = /^\s*((\d+)h)?(\s(\d+)m)?\s*$/

export const humanDurationToSp = (humanDuration: string) => {
  const parsed = humanDuration.match(SPENT_TIME_CATCHER)
  const spentH = parsed?.[2]
  const spentM = parsed?.[4]
  if (spentH === undefined && spentM === undefined) {
    throw new Error(`invalid spent time: ${humanDuration}`)
  }
  const totSpentSP =
    ((spentH ? parseInt(spentH) : 0) * 60 + (spentM ? parseInt(spentM) : 0)) /
    60
  return totSpentSP
}

/**
 * returns a human readable duration from a given number (positive or negative) of story points
 * @param sp
 */
export const spToHumanDuration = (sp: number) => {
  const spAbs = Math.abs(sp)
  const hours = Math.floor(spAbs)
  const minutes = Math.round((spAbs - hours) * 60)
  return `${sp > 0 ? '' : '-'}${hours}h ${minutes}m`
}

type SortType = 'asc' | 'desc'
export const sortByISODate = (sortType: SortType, a: ISODate, b: ISODate) => {
  return sortType === 'asc' ? a.localeCompare(b) : b.localeCompare(a)
}
