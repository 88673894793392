import { seedsOrder } from '~/api/issues/models/specialIssues/SeedsOrder'
import { createSeed, getSeeds } from '..'
import {
  type Cluster,
  type SeedCustomData,
  SeedR,
  type TSeed,
  getClusterLabel,
} from './Seed'
import { getUpdatedDescription } from '~/api/issues/mapping'

const getAllSeeds = singletonFactory(() =>
  getSeeds().then((seeds) => seeds.map(SeedR)),
)

class SeedsHub {
  private _seeds?: TSeed[]
  private _orderedIids?: number[]
  async loadSeeds() {
    const [seeds, orderedIids] = await Promise.all([
      getAllSeeds(),
      seedsOrder.load(),
    ])
    if (!this._seeds) this._seeds = seeds
    if (!this._orderedIids) this._orderedIids = orderedIids
    return this._seeds
  }
  get seeds() {
    // side effect implicit load
    this.loadSeeds()
    return this._seeds?.orderByIid(this._orderedIids ?? [])
  }

  get openIssues() {
    return this.seeds?.flatMap((seed) => seed.openIssues).distinctByIid() ?? []
  }

  get openIssuesWeight() {
    return this.openIssues.sum((i) => i.weight)
  }

  get rAndDOpenIssues() {
    return this.openIssues.filter((issue) => issue.isRAndD)
  }

  get rAndDOpenIssuesWeight() {
    return this.rAndDOpenIssues.sum((i) => i.weight)
  }

  async createSeed(
    title: string,
    okrImpacts: string,
    qualitativeBizImpacts: string,
    quantitativeBizImpacts: string,
    notes: string,
    cluster: Cluster,
  ) {
    if (!this._seeds) throw new Error('seeds not loaded')
    const customData: SeedCustomData = {
      okrImpacts,
      qualitativeBizImpacts,
      quantitativeBizImpacts,
      notes,
    }
    const seedIssue = await createSeed(
      title,
      getUpdatedDescription(customData),
      [getClusterLabel(cluster)],
    )
    const seed = SeedR(seedIssue)
    this._seeds.unshift(seed)
    return seed
  }
  /**
   * seed removal: CLOSE the issue first to be not visible when refetching!
   * @param seed
   * @returns
   */
  async deleteSeed(seed: TSeed) {
    if (!this._seeds) throw new Error('seeds not loaded')
    const index = this._seeds.indexOf(seed)
    if (index === -1) return
    this._seeds.splice(index, 1)
  }
  async moveSeed(moveFrom: TSeed, moveTo: TSeed) {
    if (!this._orderedIids || !this.seeds)
      throw new Error('ordered iids or seeds not loaded')
    if (moveFrom === moveTo) return
    const order = this.seeds.map((seed) => seed.iid)
    const moveFromIndex = order.indexOf(moveFrom.iid)
    const moveToIndex = order.indexOf(moveTo.iid)
    order.move(moveFromIndex, moveToIndex)

    if (order.equals(this._orderedIids)) return

    // optimistic update
    this._orderedIids = order
    const updated = await seedsOrder.save(order)
    this._orderedIids = updated
  }
}

export const seedsHub = reactifyClass(SeedsHub)()
