import { getAllFiltered, glGetAll } from '~/api/common'
import { CFG, LABELS } from '~/services/cfg'
import { Bug, BugR } from './index'

const generalProjectId = CFG.projects.general.id

const fetchBugs = async (state: GLMRState) =>
  (
    await getAllFiltered(
      (params) =>
        glGetAll<GLIssue[]>(generalProjectId.toString(), `issues`, params),
      {
        hasAllLabels: [LABELS.bugfix],
        state,
      },
    )
  ).map(BugR)

const loadOpened = singletonFactory(() => fetchBugs('opened'))
const loadClosed = singletonFactory(() => fetchBugs('closed'))

class BugsHub {
  private _openedBugs?: Bug[]
  private _closedBugs?: Bug[]

  async loadOpened() {
    const bugs = await loadOpened()
    if (!this._openedBugs) this._openedBugs = bugs
    return this._openedBugs
  }

  async loadClosed() {
    const bugs = await loadClosed()
    if (!this._closedBugs) this._closedBugs = bugs
    return this._closedBugs
  }

  get openBugs() {
    // side effect implicit load
    this.loadOpened()
    return this._openedBugs
  }

  get closedBugs() {
    // side effect implicit load
    this.loadClosed()
    return this._closedBugs
  }

  get bugs() {
    if (this.openBugs && this.closedBugs) {
      return [...this.openBugs, ...this.closedBugs]
    }
  }

  get count() {
    return this.bugs?.length ?? 0
  }

  get hiPrioCount() {
    return this.bugs?.filter((b) => b.priority === 'High') ?? []
  }

  get unclassified() {
    return this.bugs?.filter((b) => !b.priority) ?? []
  }
}

export const bugsHub = reactifyClass(BugsHub)()
