import { SpecialIssue } from './dataContainer'

export class OrderManager extends SpecialIssue<number[]> {
  save(order: number[]) {
    this._data = order
    return super.save(order)
  }
  get orderedIids() {
    return this.data
  }
}
