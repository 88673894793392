import { loadIssueLinks } from '~/api/issues/models/common'
import {
  createIssue,
  getIssue,
  getIssueEntities,
  updateDescription as updateIssueDesc,
  updateLabels as updateIssueLabels,
  updateTitle as updateIssueTitle,
} from '~/api/issues'
import { CFG, KSCRUM_LABELS } from '~/services/cfg'

const generalProjectId = CFG.projects.general.id

export const getSeed = getIssue.bind(null, generalProjectId)

export const getSeeds = getIssueEntities.bind(null, generalProjectId, {
  hasAllLabels: [KSCRUM_LABELS.seeds],
})

export const updateDescription = updateIssueDesc.bind(null, generalProjectId)

export const updateLabels = updateIssueLabels.bind(null, generalProjectId)

export const updateTitle = updateIssueTitle.bind(null, generalProjectId)

export const createSeed = async (
  title: string,
  description: string,
  labels: string[],
) => {
  const seed = await createIssue(generalProjectId, title, {
    description,
    labels: [KSCRUM_LABELS.seeds, ...labels],
  })
  return seed
}

export const loadSeedIssues = async (seedIid: number) =>
  loadIssueLinks(generalProjectId, seedIid).then((links) =>
    links.filter(
      (link) =>
        link.state !== 'closed' &&
        link.link_type === 'relates_to' &&
        link.project_id === generalProjectId,
    ),
  )
