import dayjs, { Dayjs } from 'dayjs'
import {
  andrea,
  davide,
  diphlo,
  francesco,
  libor,
  mauro,
  nic,
  stefano,
  tommy,
  vlad,
} from './cfg'

type Seniority = 0.4 | 0.6 | 0.8

type Period = {
  start: Dayjs
  pf: number
  updates?: Map<User, Seniority>
}

const PERIODS: Record<string, Period> = {
  'Q3/23': {
    start: dayjs('2023-07-01'),
    pf: 0.4528242106,
    updates: new Map<User, Seniority>([
      [davide, 0.6], // TODO: update to 0.8 by end of Q2/24
      [tommy, 0.8],
      [stefano, 0.6],
      [vlad, 0.6],
      [andrea, 0.6],
      [francesco, 0.6],
      [diphlo, 0.8],
      [mauro, 0.6],
      [nic, 0.6],
      [libor, 0.8],
    ]),
  },
  'Q4/23': {
    start: dayjs('2023-10-01'),
    pf: 0.5709166744, // updated to 10/01/24 with latest benchmarking data coming from Q4/23
  },
  'Q1/24': {
    start: dayjs('2024-01-01'),
    pf: 0.5709166744,
  },
  'Q2/24': {
    start: dayjs('2024-04-01'),
    pf: 0.5773717949, // updated to 22/07/24 with latest benchmarking data coming from Q2/24
  },
}

const initialPeriod = PERIODS['Q3/23']!
export const defaultSeniority: Seniority = 0.4

/**
 * gets the most recent period's pf started before the given date
 * @param date
 */
export const getPf = (date: Dayjs) => {
  const period = Object.values(PERIODS).reduce((acc, curr) => {
    if (!curr.start.isAfter(date) && curr.start.isAfter(acc.start)) return curr
    return acc
  }, initialPeriod)
  return period.pf
}

export const buildGetSeniority = (date: Dayjs) => (user: User) => {
  if (date.isBefore(initialPeriod.start)) return defaultSeniority
  let lastUpdatePeriod: Period | undefined
  for (const period of Object.values(PERIODS)) {
    if (
      (!lastUpdatePeriod || period.start.isAfter(lastUpdatePeriod.start)) &&
      !!period.updates?.has(user) &&
      !period.start.isAfter(date)
    )
      lastUpdatePeriod = period
  }

  if (!lastUpdatePeriod) {
    console.error(`No seniority found for ${user.glUsername} at ${date}`)
    return defaultSeniority
  }
  return lastUpdatePeriod.updates!.get(user)!
}
