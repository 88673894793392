export const baseArrayExtensions = {
  groupBy<T, K extends string | number>(
    this: T[],
    fn: (n: T) => K,
  ): Record<K, T[]> {
    return this.reduce(
      (acc, n) => {
        const key = fn(n)
        const group = (acc[key] = acc[key] ?? [])
        group.push(n)
        return acc
      },
      {} as Record<K, T[]>,
    )
  },
  /**
   * splits an array in 2 arrays based on the `isFirstChunk` function
   * @param this
   * @param isFirstChunk when it returns true, the item goes to the first chunk, otherwise to the second
   * @returns
   */
  splitBy<T>(this: T[], isFirstChunk: (n: T) => boolean) {
    return [
      this.filter(isFirstChunk),
      this.filter((n) => !isFirstChunk(n)),
    ] as const
    // TODO: following code does not compile with old TS version (5.1.6), while it compiles with new ones (5.5.4)
    // When we upgrade to v5.5.4, we can use following optimized code instead of the above one

    // const { a, b } = Object.groupBy(this, (x: T) =>
    //   isFirstChunk(x) ? 'a' : 'b',
    // )
    // return [a ?? [], b ?? []] as const
  },
  firstBy<T>(this: T[], aGoesFirst: (a: T, b: T) => boolean) {
    return this.length === 0
      ? undefined
      : this.reduce((a, b) => (aGoesFirst(a, b) ? a : b))
  },
  /**
   * just syntactic sugar for `at(0)`
   * @param this
   * @returns
   */
  first<T>(this: T[]) {
    return this.at(0)
  },
  last<T>(this: T[]) {
    return this.length === 0 ? undefined : this[this.length - 1]
  },
  /**
   * returns a shallow copy of the array having the item at the `from` index moved to the `to` index
   * @param this
   * @param from
   * @param to
   */
  move<T>(this: T[], from: number, to: number) {
    const [removed] = this.splice(from, 1)
    this.splice(to, 0, removed!)
    return this
  },
  moveI<T>(this: T[], from: number, to: number) {
    return [...this].move(from, to)
  },
  removeBy<T>(this: T[], selector: (item: T) => boolean) {
    const idx = this.findIndex(selector)
    this.removeByIndex(idx)
  },
  removeByIndex<T>(this: T[], index: number) {
    if (index >= 0) {
      this.splice(index, 1)
    }
  },
  /**
   * remove with immutability (not in place)
   * @param this
   * @param item
   */
  removeI<T>(this: T[], item: T) {
    return this.filter((n) => n !== item)
  },
  remove<T>(this: T[], item: T) {
    const idx = this.indexOf(item)
    this.removeByIndex(idx)
  },
  removeAll<T>(this: T[], items: T[]) {
    items.forEach((i) => this.remove(i))
  },
  /**
   * remove the item from the array if it exists, otherwise add it
   *
   * @param this
   * @param item
   */
  toggle<T>(this: T[], item: T) {
    if (this.includes(item)) {
      this.remove(item)
    } else {
      this.push(item)
    }
  },
  /**
   * remove the item from the array if it exists, otherwise add it.
   * Returns a new array without modifying the original
   *
   * @param this
   * @param item
   * @returns
   */
  toggleI<T>(this: T[], item: T) {
    const arr = [...this]
    arr.toggle(item)
    return arr
  },
  distinct<T>(this: T[], getKey?: (item: T) => string | number) {
    if (getKey) {
      const keys = this.map(getKey)
      return this.filter((_, i) => keys.indexOf(keys[i]!) === i)
    }
    return [...new Set(this)]
  },
  isEmpty<T>(this: T[]) {
    return this.length === 0
  },
  count<T>(this: T[], fn: (n: T) => boolean) {
    return this.filter(fn).length
  },
  sum<T>(this: T[], fn: (n: T) => number) {
    return this.reduce((acc, n) => acc + fn(n), 0)
  },
  avg<T>(this: T[], fn: (n: T) => number, defaultIfEmpty?: number) {
    if (this.isEmpty()) {
      return defaultIfEmpty ?? NaN
    }
    return this.sum(fn) / this.length
  },
  max<T>(this: T[], fn: (n: T) => number) {
    return this.reduce((acc, n) => Math.max(acc, fn(n)), -Infinity)
  },
  /**
   * returns true if the array contains all the items in the `items` array
   * @param this
   * @param other
   * @param compareFn returns true if the two items are considered equal. Defaults to (a, b) => a === b
   * @param sameOrder when false, the order of the items in the array does not matter. Defaults to true
   * @returns
   */
  equals<T>(
    this: T[],
    other: T[],
    compareFn: (a: T, b: T) => boolean = (a, b) => a === b,
    sameOrder = true,
  ): boolean {
    if (this.length !== other.length) {
      return false
    }
    if (!sameOrder) {
      // any element of the first array should hava a single corresponding element in the second array
      // elements can have multiple occurrences, so we need to remove them from the second array as they are matched
      const otherCopy = [...other]
      return this.every((n) => {
        const idx = otherCopy.findIndex((o) => compareFn(n, o))
        if (idx === -1) {
          return false
        }
        otherCopy.splice(idx, 1)
        return true
      })
    }
    return this.every((n, i) => compareFn(n, other[i]!))
  },
  replace<T>(this: T[], newItem: T, selector: (item: T) => boolean) {
    const idx = this.findIndex(selector)
    const found = idx !== -1
    if (found) {
      this.splice(idx, 1, newItem)
    }
    return found
  },
  /**
   * replace if found, otherwise push.
   * If no selector is specified, it will replace the first item found having the same value as the newItem
   * @param this
   * @param newItem
   * @param selector
   */
  set<T>(
    this: T[],
    newItem: T,
    selector: (item: T) => boolean = (item: T) => item === newItem,
  ) {
    if (!this.replace(newItem, selector)) {
      this.push(newItem)
    }
  },
  // TODO: improve! this currently does not check that items are of type T but infers items type to `this`
  // i.e: roles.includesAny(['CTO', 55]) is valid (having roles: UserRole[])
  includesAny<T>(this: T[], items: T[]) {
    return items.some((i) => this.includes(i))
  },
}
