import {
  type SprintTechIssue,
  type ValueWithIssuesRef,
} from '~/components/burndown/types'
import { Sprint } from './Sprint'

/**
 * computes a `extractedValue` for any tech issue linked to given general issues
 * selects just issues having a tech issues contributing to the aggregate value (extractedValue !== 0)
 * returns the sum of all extractedValue and the list of issues contributing to the aggregate
 * @param issues
 * @param valueExtractor
 * @returns
 */
const getAggregateWithReferences = (
  issues: SprintTechIssue[],
  valueExtractor: (issue: SprintTechIssue) => number,
): ValueWithIssuesRef => {
  const guilty: SprintTechIssue[] = []
  let tot = 0
  issues.forEach((ti) => {
    const computed = valueExtractor(ti)
    if (computed !== 0) {
      tot += computed
      guilty.push(ti)
    }
  })
  return { value: tot, issues: guilty }
}

export class ReleaseStats {
  constructor(
    private sprint: Sprint,
    private domain: Domain | 'all',
    private team: TeamName | 'all',
    private released: boolean = true,
  ) {}

  private get releasedIssues() {
    return this.sprint.issues?.filter((i) => i.isReleased) ?? []
  }

  get issues() {
    let issues = this.released ? this.releasedIssues : this.sprint.issues ?? []
    if (this.team !== 'all') {
      issues = issues.filter((i) => i.teamName === this.team)
    }
    if (this.domain !== 'all') {
      issues = issues.filter(
        (i) => !!i.techIssues?.some((ti) => ti.domain === this.domain),
      )
    }
    return issues
  }

  private get techIssues() {
    const domain = this.domain
    if (domain === 'all') return this.issues.flatMap((i) => i.techIssues ?? [])
    return this.issues.flatMap(
      (i) => i.techIssues?.filter((ti) => ti.domain === domain) ?? [],
    )
  }

  private get nthTechIssues() {
    return this.techIssues.filter((ti) => ti.relatedIssue.isNth)
  }

  private get mandatoryTechIssues() {
    return this.techIssues.filter((ti) => !ti.relatedIssue.isNth)
  }

  get nthSpent() {
    return getAggregateWithReferences(
      this.nthTechIssues,
      (ti) => ti.sprintSpentSP,
    )
  }
  get nthReleased() {
    return getAggregateWithReferences(
      this.nthTechIssues,
      (ti) => ti.sprintStartWeight,
    )
  }

  get mandatorySpent() {
    return getAggregateWithReferences(
      this.mandatoryTechIssues,
      (ti) => ti.sprintSpentSP,
    )
  }

  get mandatoryReleased() {
    return getAggregateWithReferences(
      this.mandatoryTechIssues,
      (ti) => ti.sprintStartWeight,
    )
  }

  get totalSpent() {
    return this.mandatorySpent.value + this.nthSpent.value
  }

  get totalReleased() {
    return this.mandatoryReleased.value + this.nthReleased.value
  }

  get absoluteDelta() {
    return getAggregateWithReferences(this.techIssues, (ti) =>
      Math.abs(ti.deltaSpent),
    )
  }

  get overEstimate() {
    return getAggregateWithReferences(this.techIssues, (ti) => ti.overEstimate)
  }

  get underEstimate() {
    return getAggregateWithReferences(this.techIssues, (ti) => ti.underEstimate)
  }
}

export const ReleaseStatsR = reactifyClass(ReleaseStats)
