import dayjs, { type PluginFunc } from 'dayjs'

export const extendDayjs = function <TExtensions extends object>(
  extensions: TExtensions,
) {
  const plugin: PluginFunc<unknown> = function (
    _option,
    dayjsClass,
    dayjsFactory,
  ) {
    var proto = dayjsClass.prototype
    Object.assign(proto, extensions)
  }
  dayjs.extend(plugin)
}
