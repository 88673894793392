import { IssueBase } from './index'
import { CFG, LABELS } from '~/services/cfg'
import { issuesHub } from './IssueHub'
import { updateWeight } from '..'

/**
 * this factor is used to multiply the weight of tech debts and make it possible to use
 * a bigger granularity for them (from 0.25SP on).
 * Reason: Gitlab does not allow to set a weight lower than 1.
 */
const TD_WEIGHT_MULTIPLIER = 4

export class TechIssue extends IssueBase {
  // #region tech debt management

  get techDebtsWeight() {
    return this.techDebts.sum((x) => x.weight ?? 0)
  }

  get techDebts() {
    const projName = this.projectName
    if (projName === 'BE' || projName === 'SF' || projName === 'BO') {
      return (
        issuesHub.techDebts[projName]?.filter(
          (td) => td.relatedTechIssue?.iid === this.iid,
        ) ?? []
      )
    }
    return []
  }
  // #endregion

  get isBudget() {
    return this.labels.includes(LABELS.budget)
  }

  setIsBudget(isBudget: boolean) {
    return this.updateAndPersistLabels(
      [LABELS.budget],
      isBudget ? [LABELS.budget] : [],
    )
  }

  /**
   * indicates if a tech issue belongs to BE or FE project
   */
  get domain() {
    return Object.values(CFG.projects).find((p) => p.id === this.projectId)
      ?.domain
  }

  get isTechDebt() {
    return this.labels.includes(LABELS.techDebt)
  }

  get startWeight() {
    return this.customData.startWeight
  }

  setStartWeight(startWeight: number) {
    return this.setCustomData('startWeight', startWeight)
  }

  get tShirt() {
    return this.customData.tShirt
  }

  setTShirt(tShirt?: number, debounced = false) {
    return this.setCustomData('tShirt', tShirt, debounced)
  }

  get weightEvents() {
    if (this.isTechDebt) {
      return super.weightEvents.map((e) => ({
        ...e,
        weight: e.weight ? e.weight / TD_WEIGHT_MULTIPLIER : e.weight,
      }))
    }
    return super.weightEvents
  }

  get isEstimated() {
    return this.estimate !== undefined
  }

  get estimate() {
    return this._fromApi.weight ?? undefined
  }

  /**
   * not yet used: to be used instead of current estimate()
   * we need to:
   * - update setEstimate by using APIs (values to be transformed in human readable format, see https://docs.gitlab.com/ee/api/issues.html#set-a-time-estimate-for-an-issue)
   * - check consistency for all the flows using "estimate" (sprint follow-up, result snapshots, planning)
   * - migrate all current sprint and planning weights to the new estimate
   */
  get estimate2() {
    // from seconds to SP
    return this._fromApi.time_stats.time_estimate / (60 * 60 * 4)
  }

  get weight() {
    const bestWeight = this.estimate ?? this.tShirt
    return this.isTechDebt && bestWeight
      ? bestWeight / TD_WEIGHT_MULTIPLIER
      : bestWeight
  }

  async setEstimate(weight?: number, debounced = false) {
    const updated = await this.queue(
      'setEstimate',
      async () => {
        const w =
          weight === undefined
            ? null
            : this.isTechDebt
            ? weight * TD_WEIGHT_MULTIPLIER
            : weight
        return updateWeight(this.projectId, this.iid, w)
      },
      debounced,
    )
    if (updated) this._fromApi.weight = updated.weight
  }
}

export const TechIssueR = reactifyClass(TechIssue)
