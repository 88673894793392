import { mergeNonOverlapping } from '../utils'
import { baseArrayExtensions } from './base'
import { withIidArrayExtensions } from './withIid'

type BaseArrayExtensions = typeof baseArrayExtensions
type WithIidArrayExtensions = typeof withIidArrayExtensions

type ArrayExtensions = BaseArrayExtensions & WithIidArrayExtensions
declare global {
  interface Array<T> extends ArrayExtensions {}
}

// extends the Array prototype with multiple objects's methods
Object.assign(
  Array.prototype,
  mergeNonOverlapping(baseArrayExtensions, withIidArrayExtensions),
)

export {}
