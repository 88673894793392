/**
 * this module creates an abstraction layer that uses issues to store any entity data.
 * It uses just issue description as a JSON stringified object.
 *
 * In order to create a new entity, you need to create a new issue in GitLab, add the
 * issue ID as a constant below and use abstraction to gather/save the payload
 *
 */

import { CFG } from '~/services/cfg'
import { getIssue, updateDescription } from '~/api/issues'

// https://gitlab.com/kampaay/general/-/issues/3075
export const SPRINTS_CFG_ISSUE_ID = 3075

// https://gitlab.com/kampaay/general/-/issues/2734
export const SPRINTS_RESULTS_ISSUE_ID = 2734

// https://gitlab.com/kampaay/general/-/issues/3054
export const PLANNING_ISSUE_ORDER = 3054

// https://gitlab.com/kampaay/general/-/issues/3095
export const SEEDS_ISSUE_ORDER = 3095

const generalProjectId = CFG.projects.general.id

const loadPayloadBuilder = <TEntity>(issueId: number) =>
  singletonFactory(async () => {
    const issue = await getIssue(generalProjectId, issueId)
    return JSON.parse(issue.description!) as TEntity
  })
type LoadPayloadBuilderResult<TEntity> = ReturnType<
  typeof loadPayloadBuilder<TEntity>
>

const savePayloadBuilder =
  <TEntity extends object>(
    issueId: number,
    loadForce: () => Promise<TEntity>,
  ) =>
  async (updaterOrValue: TEntity | ((data: TEntity) => TEntity)) => {
    // reducing concurrency problems: we avoid overriding the whole payload with data we fetched time ago.
    // We fetch the most recent version and update only the specific part
    const data = await loadForce()

    await updateDescription(
      generalProjectId,
      issueId,
      JSON.stringify(
        typeof updaterOrValue === 'function'
          ? updaterOrValue(data)
          : updaterOrValue,
      ),
    )
    return loadForce()
  }

const getAccessors = <TEntity extends object>(issueId: number) => {
  const loadPayload = loadPayloadBuilder<TEntity>(issueId)
  const savePayload = savePayloadBuilder<TEntity>(issueId, loadPayload.force)
  return [loadPayload, savePayload] as const
}

export class SpecialIssue<TEntity extends object> {
  protected _data?: TEntity
  private _load: LoadPayloadBuilderResult<TEntity>
  constructor(private issueId: number) {
    this._load = loadPayloadBuilder<TEntity>(issueId)
  }
  async load(force = false) {
    if (force) {
      this._data = await this._load.force()
    } else if (!this._data) {
      this._data = await this._load()
    }
    return this._data
  }
  protected get data() {
    // side effect implicit load
    this.load()
    return this._data
  }
  protected async save(updaterOrValue: TEntity | ((data: TEntity) => TEntity)) {
    // reducing concurrency problems: we avoid overriding the whole payload with data we fetched time ago.
    // We fetch the most recent version and update only the specific part
    const data = await this._load.force()

    const updated = await updateDescription(
      generalProjectId,
      this.issueId,
      JSON.stringify(
        typeof updaterOrValue === 'function'
          ? updaterOrValue(data)
          : updaterOrValue,
      ),
    )
    return JSON.parse(updated.description!) as TEntity
  }
}

// const SpecialIssueR = reactifyClass(SpecialIssue)

// export const sprintsCfg = SpecialIssueR(3075)
// export const sprintsResults = SpecialIssueR(2734)
// export const planningOrder = SpecialIssueR(3054)

export const getSprintCfgAccessors = <TEntity extends object>() =>
  getAccessors<TEntity>(SPRINTS_CFG_ISSUE_ID)

// TODO: use this after migration of results to the new issue
export const getSprintResultsAccessors = <TEntity extends object>() =>
  getAccessors<TEntity>(SPRINTS_RESULTS_ISSUE_ID)

export const getPlanningOrderAccessors = <TEntity extends object>() =>
  getAccessors<TEntity>(PLANNING_ISSUE_ORDER)
