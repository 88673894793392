import isToday from 'dayjs/plugin/isToday'
import weekday from 'dayjs/plugin/weekday'
import updateLocale from 'dayjs/plugin/updateLocale'
import dayjs, { type OpUnitType } from 'dayjs'
import { extendDayjs } from './common'

// custom extensions code
const customExtensions = {
  toDayMonth(this: DayJs) {
    return this.format('DD/MM')
  },
  /**
   * allows to format a date to a string ignoring time.
   * The result can be parsed back to a dayjs object using `dayjs(string)`
   * @param this
   */
  toDateString(this: DayJs) {
    return this.format('YYYY-MM-DD')
  },
  toDateTime(this: DayJs) {
    return this.format('D/MM - HH:mm')
  },
  isBetween(this: DayJs, start: DayJs, end: DayJs, unit?: OpUnitType) {
    return !this.isBefore(start, unit) && !this.isAfter(end, unit)
  },
  isPast(this: DayJs, unit?: OpUnitType) {
    return this.isBefore(dayjs(), unit)
  },
  isFuture(this: DayJs, unit?: OpUnitType) {
    return this.isAfter(dayjs(), unit)
  },
  isWeekend(this: DayJs) {
    return this.weekday() > 4
  },
  rangeUntil(this: DayJs, end: DayJs) {
    return Array.from({ length: end.diff(this, 'day') }, (_, i) =>
      this.add(i, 'day'),
    )
  },
}

// extend through custom plugins
extendDayjs(customExtensions)

// extend through lib provided plugins
dayjs.extend(updateLocale)
dayjs.extend(isToday)
dayjs.extend(weekday)

dayjs.updateLocale('en', {
  weekStart: 1, // Monday is the first day of the week
})

// extend through custom plugins (declarations)
type CustomExtensions = typeof customExtensions
declare module 'dayjs' {
  interface Dayjs extends CustomExtensions {}
}
