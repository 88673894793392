import { glCreate, glGet, glGetAll } from '~/api/common'

export const KSCRUM_DISCUSSION_SYMBOL = '[K-d]'
export const KSCRUM_SOLVED_SYMBOL = '[K-s]'
export const KSCRUM_REOPENED_SYMBOL = '[K-r]'

export const discussionRegex = /^\[K-d\]/

export const getUserDiscussions = async (
  projectId: number,
  issueIid: number,
) => {
  const disc = await glGetAll<GLDiscussion[]>(
    projectId.toString(),
    `issues/${issueIid}/discussions`,
  )
  return disc.filter((d) => d.notes[0] && d.notes[0]?.system === false)
}

export const getUserDiscussion = async (
  projectId: number,
  issueIid: number,
  discussionId: number,
) => {
  const disc = await glGet<GLDiscussion>(
    projectId.toString(),
    `issues/${issueIid}/discussions/${discussionId}`,
  )
  return disc.data
}

export const createDiscussion = (
  projectId: number,
  issueIid: number,
  body: string,
) =>
  glCreate<GLDiscussion>(
    projectId.toString(),
    `issues/${issueIid}/discussions`,
    { body: `${KSCRUM_DISCUSSION_SYMBOL}\n${body}` },
  )

export const replyToDiscussion = async (
  projectId: number,
  issueIid: number,
  discussionId: number,
  body: string,
) => {
  await glCreate<GLNote>(
    projectId.toString(),
    `issues/${issueIid}/discussions/${discussionId}/notes`,
    { body },
  )
}

export const setDiscussionState = async (
  projectId: number,
  issueIid: number,
  discussionId: number,
  state: 'solved' | 'reopened',
) => {
  await glCreate<GLNote>(
    projectId.toString(),
    `issues/${issueIid}/discussions/${discussionId}/notes`,
    {
      body: state === 'solved' ? KSCRUM_SOLVED_SYMBOL : KSCRUM_REOPENED_SYMBOL,
    },
  )
}
