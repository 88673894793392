import { Issue, issueR, TechDebt, TechDebtR } from '.'
import { getIssues, getTechDebts } from '..'
import { CFG, LABELS } from '~/services/cfg'

const loadCandidateIssues = singletonFactory(() =>
  getIssues(CFG.projects.general.id, {
    milestone: null,
    hasNoneLabels: [LABELS.documentation, LABELS.bugfix],
  }).then((issues) => issues.map(issueR)),
)

const loadTechDebts = singletonFactory(
  (project: ProjectName) => getTechDebts(CFG.projects[project].id),
  (project: ProjectName) => project,
)

const batchLoadTechDebts = semaphoreBuilder()(loadTechDebts)

const techDebtsByDomain = reactifyClass(
  class {
    constructor(private issues: IssuesHub) {}
    get fe() {
      return (this.issues.techDebts.BO ?? [])
        .concat(this.issues.techDebts.SF ?? [])
        .sort((a, b) => b.createdAt.diff(a.createdAt))
    }
    get be() {
      return this.issues.techDebts.BE ?? []
    }
    get all() {
      console.log('>>> called', ++count)
      return this.fe
        .concat(this.be)
        .sort((a, b) => b.createdAt.diff(a.createdAt))
    }
  },
)

let count = 0

/**
 * readonly hub for issues & tech debts
 */
class IssuesHub {
  private _unassigned?: Issue[]
  private _techDebts: Partial<Record<ProjectName, TechDebt[]>> = {}

  async loadTechDebts(project: TechProjectName) {
    const issues = await batchLoadTechDebts(project)
    if (this._techDebts[project] !== undefined) return
    this._techDebts[project] = issues.map(TechDebtR)
  }

  techDebts = ((_this: IssuesHub) => ({
    get SF() {
      _this.loadTechDebts('SF')
      return _this._techDebts.SF
    },
    get BO() {
      _this.loadTechDebts('BO')
      return _this._techDebts.BO
    },
    get BE() {
      _this.loadTechDebts('BE')
      return _this._techDebts.BE
    },
  }))(this)

  techDebtsByDomain = techDebtsByDomain(this)

  // #region unassigned issues
  async loadUnassigned() {
    const issues = await loadCandidateIssues()
    if (!this._unassigned) this._unassigned = issues
    return this._unassigned
  }

  addUnassigned(issue: Issue) {
    this._unassigned?.unshift(issue)
  }

  removeUnassigned(issue: Issue) {
    this._unassigned?.removeBy((i) => i.iid === issue.iid)
  }

  /**
   * unassigned issues (no sprint assigned)
   */
  get unassignedIssues() {
    // side effect implicit load
    this.loadUnassigned()
    return this._unassigned
  }
  // #endregion
}

export const issuesHub = reactifyClass(IssuesHub)()
