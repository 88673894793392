import { getApiBasePath, getGroupAccessToken } from '~/services/runtimeCfg'
import { getFetchWrapper } from '~/services/auth'
import { registerCall, unregisterCall } from './fetchCount'
import { isGuest } from '~/plugins/3.guestManagement'
export { isFetching } from './fetchCount'

// gitlab rate limits:
// Authenticated API traffic (for a given user)	2,000 requests per minute
// https://docs.gitlab.com/ee/user/gitlab_com/index.html#gitlabcom-specific-rate-limits

const MAX_PAGES = 20
const DEFAULT_PER_PAGE = 100

const GL_HEADERS = [
  'x-page',
  'x-per-page',
  'x-next-page',
  'x-prev-page',
  'x-total',
  'x-total-pages',
  'ratelimit-limit',
  'ratelimit-remaining',
  'ratelimit-reset',
] as const

type GLHeader = (typeof GL_HEADERS)[number]

type GLHeaderRecord = Record<GLHeader, number>

const fetchWithRetry = async (
  input: RequestInfo,
  init?: RequestInit | undefined,
  retries = 3,
): Promise<Response> => {
  try {
    if (isGuest.value) {
      // use the group access token for guest users (READONLY!)
      return await fetch(input, {
        ...init,
        headers: {
          'PRIVATE-TOKEN': getGroupAccessToken(),
        },
      })
    }
    return await getFetchWrapper().fetch(input, init)
  } catch (error) {
    if (--retries > 0) {
      return fetchWithRetry(input, init, retries)
    }
    throw error
  }
}

const glFetch = async (
  path: string,
  init?: RequestInit | undefined,
): Promise<Response> => {
  registerCall()
  try {
    const defaultedInit = {
      ...init,
      headers: {
        ...(init?.headers ?? {}),
        'Content-Type': 'application/json',
      },
    }
    const response = await fetchWithRetry(path, defaultedInit)
    if (!response.ok && response.status === 401) {
      console.error('401', response)
      throw new Error(`unexpected API error for ${path}`)
    }
    return response
  } catch (error) {
    throw error
  } finally {
    unregisterCall()
  }
}

export const glGet = async <T>(
  projectId: string | null,
  path: string,
  params: Record<string, string> = {},
) => {
  const qs = new URLSearchParams(params)
  // when projectId is null, we are fetching the resource using 'path' without any prefixing
  const resourcePath =
    projectId === null ? path : `projects/${projectId}/${path}`
  const response = await glFetch(`${getApiBasePath()}/${resourcePath}?${qs}`)

  if (!response.ok) {
    throw new Error(`Failed to fetch ${path}`)
  }
  const data = (await response.json()) as T
  const headers = GL_HEADERS.reduce((acc, header) => {
    const value = response.headers.get(header)
    if (value) {
      acc[header] = Number(value)
    }
    return acc
  }, {} as GLHeaderRecord)

  return { data, headers }
}

export const glGetAll = async <T extends any[]>(
  projectId: string | null,
  path: string,
  qs: Record<string, string> = {},
) => {
  // page	Page number (default: 1).
  // per_page	Number of items to list per page (default: 20, max: 100).
  qs.per_page = String(DEFAULT_PER_PAGE)

  const res = await glGet<T>(projectId, path, qs)

  const {
    data,
    headers: { 'x-total-pages': totalPages, 'x-next-page': nextPage },
  } = res

  if (totalPages > MAX_PAGES) {
    throw new Error(
      `exceeded max pages (${totalPages} > ${MAX_PAGES}) for API call: "${path}"`,
    )
  }

  if (!!nextPage) {
    const nextRes = await glGetAll<T>(projectId, path, {
      ...qs,
      page: nextPage.toString(),
    })
    const a = [...data, ...nextRes] as T
    return a
    // return nextRes
    // return [...res.data, ...nextRes]
  }
  return data
}

export const getAllFiltered = async <T extends any[]>(
  fetcher: (baseQs: Record<string, string>) => Promise<T>,
  filter: GLFilter = {},
) => {
  const {
    hasSomeLabels = [],
    hasAllLabels = [],
    hasNoneLabels = [],
    state,
    createdAfter,
    createdBefore,
    milestone,
    assigneeId,
    ...otherFilters
  } = filter

  if (hasAllLabels.length && hasSomeLabels.length) {
    throw new Error('hasAllLabels and hasSomeLabels are mutually exclusive')
  }

  const baseQs = {
    ...(assigneeId === undefined
      ? {}
      : assigneeId === null
      ? { assignee_id: 'None' }
      : { assignee_id: assigneeId.toString() }),
    ...(milestone === undefined
      ? {}
      : milestone === null
      ? { milestone: 'None' }
      : { milestone: milestone }),
    ...(state === 'any' ? {} : { state: state ?? 'opened' }),
    ...(hasNoneLabels.length && { 'not[labels]': hasNoneLabels.join(',') }),
    ...(hasAllLabels.length && { labels: hasAllLabels.join(',') }),
    ...(createdBefore && {
      created_before: createdBefore.toISOString(),
    }),
    ...(createdAfter && {
      created_after: createdAfter.toISOString(),
    }),
    ...otherFilters,
  }

  const responses = hasSomeLabels.length
    ? await Promise.all(
        hasSomeLabels.map((l) => fetcher({ ...baseQs, labels: l })),
      )
    : [await fetcher(baseQs)]

  return responses.flat()
}

export const glCreate = async <T>(
  projectId: string | null,
  path: string,
  body: Record<string, any>,
) => {
  // when projectId is null, we are fetching the resource using 'path' without any prefixing
  const resourcePath =
    projectId === null ? path : `projects/${projectId}/${path}`

  const response = await glFetch(`${getApiBasePath()}/${resourcePath}`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  return response.json() as Promise<T>
}

export const glUpdate = async <T>(
  projectId: string,
  path: string,
  body: Record<string, any>,
) => {
  const response = await glFetch(
    `${getApiBasePath()}/projects/${projectId}/${path}`,
    {
      method: 'PUT',
      body: JSON.stringify(body),
    },
  )
  return response.json() as Promise<T>
}

export const glDelete = async (projectId: string, path: string) => {
  const response = await glFetch(
    `${getApiBasePath()}/projects/${projectId}/${path}`,
    {
      method: 'DELETE',
    },
  )
  // return response.json()
}
