import { LABELS } from '~/services/cfg'
import { getSeed, updateDescription, updateLabels, updateTitle } from '..'
import {
  extractIssueDataFromDesc,
  getUpdatedDescription,
} from '~/api/issues/mapping'
import { sprintsHub } from '~/api/milestones/models/SprintsHub'
import { closeIssue } from '~/api/issues'
import { seedsHub } from './SeedsHub'
import { WithDebounce } from '~/api/common/models/WithDebounce'

export const clusters = [
  'internal efficiency',
  'customer experience',
  'tech/other',
] as const

export type Cluster = (typeof clusters)[number]

export const seedStatuses = [
  'TBD',
  'Under Definition',
  'Exploration',
  'Defined',
  'Blocked',
] as const

export type Status = (typeof seedStatuses)[number]
// one day we could need 'Closed' status
export type SeedCustomData = {
  okrImpacts: string
  qualitativeBizImpacts: string
  quantitativeBizImpacts: string
  notes: string
}

export type NewSeedData = SeedCustomData & {
  title: string
  cluster: Cluster
}

const initCustomData = (): SeedCustomData => ({
  okrImpacts: '',
  qualitativeBizImpacts: '',
  quantitativeBizImpacts: '',
  notes: '',
})

const {
  intEfficiency,
  custExperience,
  techOrOther,
  exploration,
  underDefinition,
  defined,
  blocked,
} = LABELS

const clusterMap: Record<string, Cluster> = {
  [intEfficiency]: 'internal efficiency',
  [custExperience]: 'customer experience',
  [techOrOther]: 'tech/other',
}

const statusMap: Record<string, Status> = {
  [defined]: 'Defined',
  [underDefinition]: 'Under Definition',
  [exploration]: 'Exploration',
  [blocked]: 'Blocked',
}

export const getClusterLabel = (cluster: Cluster) =>
  Object.keys(clusterMap).find((k) => clusterMap[k] === cluster)!

const mapLabels = <T>(labels: string[], map: Record<string, T>) => {
  for (const label of labels) {
    if (map[label]) return map[label as keyof typeof map]!
  }
}

const extractCustomData = (desc?: string) =>
  extractIssueDataFromDesc(initCustomData, desc)

// Issue loads seeds as for tech issues (then also blocked!) => private ids => seedsHub.seeds.filter(s => ids.includes(s.iid))
// seed.issues => sprintsHub.sprintsAndUnplanned.flatMap(s => s.issues).filter(i => i.seedIid === seed.iid)

class Seed extends WithDebounce {
  constructor(private _fromApi: GLIssue) {
    super()
  }
  private get customData(): SeedCustomData {
    return extractCustomData(this._fromApi.description)
  }
  private async setCustomData<TField extends keyof SeedCustomData>(
    field: TField,
    value: SeedCustomData[TField],
    debounced = false,
  ) {
    const updated = await this.queue(
      'setCustomData',
      async () => {
        // get the last version of the seed to avoid conflicts
        const last = await getSeed(this.iid)
        const lastCustomData = extractCustomData(last.description)
        // selectively override just the field we want to update
        lastCustomData[field] = value

        return updateDescription(
          this.iid,
          getUpdatedDescription(lastCustomData, last.description),
        )
      },
      debounced,
    )
    if (updated) this._fromApi.description = updated.description
  }
  /**
   * given a collection of possible values and selected values, returns the updated labels
   * removing non selected possible values and adding selected values
   * ensures that just selected labels per group (possibleLabels) are present
   * @param possibleValues
   * @param selected
   */
  private async updateLabels<T extends string>(
    map: Record<string, T>,
    selected: readonly T[],
  ) {
    const possibleValues = Object.keys(map)
    const labels = this._fromApi.labels.filter(
      (l) => !possibleValues.includes(l),
    )
    const newLabels = selected.map(
      (s) => Object.keys(map).find((k) => map[k] === s)!,
    )
    labels.push(...newLabels)
    // optimistic update
    this._fromApi.labels = labels
    const updated = await updateLabels(this.iid, labels)
    this._fromApi.labels = updated.labels
  }

  get iid() {
    return this._fromApi.iid
  }
  get webUrl() {
    return this._fromApi.web_url
  }
  get title() {
    return this._fromApi.title
  }
  async setTitle(title: string, debounced = false) {
    const updated = await this.queue(
      'setTitle',
      async () => {
        this._fromApi.title = title
        return updateTitle(this.iid, title)
      },
      debounced,
    )
    if (updated) this._fromApi.title = updated.title
  }

  get pastIssues() {
    return (
      sprintsHub.pastIssues?.filter((i) => !!i?.seeds?.findByIid(this.iid)) ??
      []
    )
  }

  get currentSprintIssues() {
    return (
      sprintsHub.currentSprintIssues?.filter(
        (i) => !!i?.seeds?.findByIid(this.iid),
      ) ?? []
    )
  }

  get futureIssues() {
    return (
      sprintsHub.futureIssues?.filter((i) => !!i?.seeds?.findByIid(this.iid)) ??
      []
    )
  }

  get openIssues() {
    return [...this.futureIssues, ...this.currentSprintIssues]
  }

  get issues() {
    return [
      ...this.pastIssues,
      ...this.futureIssues,
      ...this.currentSprintIssues,
    ]
  }

  get openIssuesWeight() {
    return this.openIssues.sum((i) => i.weight)
  }

  /**
   * returns the master issues grouped by sprint number
   */
  get masterIssuesBySprint() {
    return this.issues.filter((i) => i.isMaster).groupBy((i) => i.sprint.number)
  }

  // custom data

  get okrImpacts() {
    return this.customData.okrImpacts
  }
  setOkrImpacts(value: string, debounced = false) {
    return this.setCustomData('okrImpacts', value, debounced)
  }
  get qualitativeBizImpacts() {
    return this.customData.qualitativeBizImpacts
  }
  setQualitativeBizImpacts(value: string, debounced = false) {
    return this.setCustomData('qualitativeBizImpacts', value, debounced)
  }
  get quantitativeBizImpacts() {
    return this.customData.quantitativeBizImpacts
  }
  setQuantitativeBizImpacts(value: string, debounced = false) {
    return this.setCustomData('quantitativeBizImpacts', value, debounced)
  }
  get notes() {
    return this.customData.notes
  }
  setNotes(value: string, debounced = false) {
    return this.setCustomData('notes', value, debounced)
  }
  get cluster(): Cluster {
    return (
      mapLabels(this._fromApi.labels, clusterMap) ?? clusterMap[techOrOther]!
    )
  }
  setCluster(value: Cluster) {
    return this.updateLabels(clusterMap, [value])
  }
  get status(): Status {
    return mapLabels(this._fromApi.labels, statusMap) ?? 'TBD'
  }
  setStatus(value: Status) {
    return this.updateLabels(statusMap, value === 'TBD' ? [] : [value])
  }

  async close() {
    await closeIssue(this._fromApi.project_id, this._fromApi.iid)
    seedsHub.deleteSeed(this)
  }
}

export const SeedR = reactifyClass(Seed)
export type TSeed = InstanceType<typeof Seed>
