/**
 * this module handles data we store in a special issue description (so that we can keep track
 * of change history)
 * - allow loading
 * - allow saving
 * - caches its value
 */

import { getDayDate } from '~/utils/dates'

const isSprintDates = (d: unknown): d is string[] => Array.isArray(d)

export const mapDescriptionPayload = ({
  sprintDates,
  teams,
}: DescriptionPayload): GLDescriptionPayload => ({
  sprintDates: sprintDates.map((d) => d.toISOString()),
  teams,
})

export const mapGLDescriptionPayload = ({
  sprintDates,
  teams,
}: GLDescriptionPayload): DescriptionPayload => ({
  sprintDates: isSprintDates(sprintDates)
    ? sprintDates.map((d) => getDayDate(d))
    : [],
  teams,
})
