import {
  mapGLDescriptionPayload,
  mapDescriptionPayload,
} from '../sprintsPayload'
import { Dayjs } from 'dayjs'
import {
  getSprintCfgAccessors,
  getSprintResultsAccessors,
} from '~/api/issues/models/specialIssues/dataContainer'

const [loadCfgPayload, saveCfgPayload] =
  getSprintCfgAccessors<GLKScrumDataIssuePayload>()

type GLSprintsResults = Record<number, GLSprintResults>

const [loadResultsPayload, saveResultsPayload] =
  getSprintResultsAccessors<GLSprintsResults>()

/**
 *
 */
class SprintsPayload {
  private _data?: GLKScrumDataIssuePayload
  private _results?: GLSprintsResults

  async load() {
    const data = await loadCfgPayload()
    if (!this._data) this._data = data
    return this._data
  }

  async loadResults() {
    const results = await loadResultsPayload()
    if (!this._results) this._results = results
    return this._results
  }

  async saveResults(sprintNumber: number, results: GLSprintResults) {
    this._results = await saveResultsPayload((data) => {
      data[sprintNumber] = results
      return data
    })
  }

  /**
   * UPSERT!
   * updates already existing sprint (if sprintNumber is already present) or
   * creates a new one
   * @param sprintNumber
   * @param teams
   * @param sprintDates
   */
  async saveSprintCfg(
    sprintNumber: number,
    teams: TeamsCfg,
    sprintDates: Dayjs[],
  ) {
    this._data = await saveCfgPayload((data) => {
      data[sprintNumber] = {
        ...(data[sprintNumber] ?? {
          results: {
            individual: {},
            issues: {},
          },
        }),
        ...mapDescriptionPayload({
          teams,
          sprintDates,
        }),
      }
      return data
    })
  }

  private get data() {
    // side effect implicit load
    this.load()
    return this._data
  }
  private get results() {
    // side effect implicit load
    this.loadResults()
    return this._results
  }
  get sprintsData() {
    const { data, results } = this
    if (!data || !results) return undefined
    return mapValues(
      (v, k): SprintDescriptionPayload => ({
        ...mapGLDescriptionPayload(v),
        results: results[k] ?? { individual: {}, issues: [] },
      }),
      data,
    )
  }
}

export const sprintPayload = reactifyClass(SprintsPayload)()
