import { devR } from './models/Dev'

const withGlAvatarUrl = <T extends { glId: number }>(item: T) => ({
  ...item,
  avatar_url: `https://gitlab.com/uploads/-/system/user/avatar/${item.glId}/avatar.png`,
})

export const KAMPAAY_GROUP_ID = 7954461

export const lore = devR(
  'loris32',
  1495445,
  ['loris32@gmail.com'],
  'Lorenzo Simone',
  'U018PQ8H4N5',
  -1, // FAKE activityLogId!
)

export const davide = devR(
  'd.specchia',
  18604867,
  ['davide.specchia@kampaay.com'],
  'Davide Specchia',
  'U062PR7FMDZ',
  2414,
)

export const stefano = devR(
  'stefano.monolo',
  15652202,
  ['stefano.monolo@kampaay.com'],
  'Stefano Monolo',
  'U05PL4G4Z9C',
  2269,
  false,
)

export const tommy = devR(
  'tommasogangemi',
  8252293,
  ['tommaso.gangemi@kampaay.com'],
  'Tommaso Gangemi',
  'U01P22D06UU',
  2272,
)

export const vlad = devR(
  'vlad_chabaniuk_kampaay',
  14309621,
  ['vladyslav.chabaniuk@kampaay.com'],
  'Vlad Chabaniuk',
  'U053AH1LHML',
  2273,
  false,
)

export const richard = devR(
  'richard.ortiz',
  11552210,
  ['richard.ortiz@kampaay.com', 'richardortiz.kampaay.com'],
  'Richard Ortiz',
  'U03F6E7UETB',
  2276,
  false,
)

export const andrea = devR(
  'a.basile',
  12690158,
  ['andrea.basile@kampaay.com'],
  'Andrea Basile',
  'U03VDAT00H2',
  2267,
  false,
)

export const maliheh = devR(
  'Maliheh.k',
  12690859,
  ['maliheh.kareshky@kampaay.com'],
  'Maliheh Kareshky',
  'U03VASZMT98',
  2277,
  false,
)

export const diphlo = devR(
  'marco.diflorio',
  9359425,
  ['marco.diflorio@kampaay.com'],
  'Marco Di Florio',
  'U028MFCF3V3',
  2275,
  false,
)

export const mauro = devR(
  'mauro.degrassi',
  12691978,
  ['mauro.degrassi@kampaay.com'],
  'Mauro Degrassi',
  'U0456LVAB09',
  2268,
  false,
)

export const ele: User = withGlAvatarUrl({
  glUsername: 'eleonora_collenberg',
  glId: 12937656,
  emails: ['eleonora.collenberg@kampaay.com'],
  name: 'Eleonora Collenberg',
  slackId: 'U04949D408J',
  isActive: false,
})

export const francesco = devR(
  'francesco.scutellaro',
  12395395,
  ['francesco.scutellaro@kampaay.com'],
  'Francesco Scutellaro',
  'U03VDAT02BE',
  2274,
  false,
)

const krzy: User = withGlAvatarUrl({
  glUsername: 'krzysztof.witkowski1',
  glId: 10268766,
  emails: ['krzysztof.witkowski@kampaay.com'],
  name: 'Krzyś Witkowski',
  slackId: 'U02N8LF5PCH',
  isActive: false,
})

export const luca: User = withGlAvatarUrl({
  glUsername: 'zaniluca',
  glId: 11050107,
  emails: ['luca.zani@kampaay.com'],
  name: 'Luca Zani',
  slackId: 'U037NQZ4HTM',
  isActive: false,
})

export const matti: User = withGlAvatarUrl({
  glUsername: 'mattia.brunello',
  glId: 7886347,
  emails: ['mattia.brunello@kampaay.com'],
  name: 'Mattia Brunello',
  slackId: 'U019S273US1',
  isActive: false,
})

export const libor = devR(
  'libor.pansky',
  7579318,
  ['libor.pansky@kampaay.com'],
  'Libor Pansky',
  'U01ECFM6HT6',
  2271,
)

export const nic = devR(
  'nicholas.tori',
  9428803,
  ['nicholas.tori@kampaay.com'],
  'Nicholas Tori',
  'U029Z41AP5J',
  2270,
)

export const marco: User = withGlAvatarUrl({
  glUsername: 'marco_ottone',
  glId: 9611944,
  emails: ['marco.ottone@kampaay.com'],
  name: 'Marco Ottone',
  slackId: 'U02DANA9RJL',
  isActive: true,
})

export const gianmarco: User = withGlAvatarUrl({
  glUsername: 'giammbo',
  glId: 6001045,
  emails: ['gianmarco.carrieri@gmail.com'],
  name: 'Gianmarco Carrieri',
  slackId: 'U01AMRG8WER',
  isActive: true,
})

export const enri: User = withGlAvatarUrl({
  glUsername: 'enrico.berto',
  glId: 6388275,
  emails: ['enrico.berto@kampaay.com'],
  name: 'Enrico Berto',
  slackId: 'U017WEFUXDE',
  isActive: true,
})

export const giampaolo: User = withGlAvatarUrl({
  glUsername: 'giampaolo.tenti',
  glId: 11830121,
  emails: ['giampaolo.tenti@kampaay.com'],
  name: 'Giampaolo Tenti',
  slackId: 'U03KBT02GPP',
  isActive: true,
})

export const CFG: Cfg = {
  projects: {
    general: {
      id: 18854524,
      url: 'https://gitlab.com/kampaay/general',
    },
    auth0: {
      id: 24785622,
      url: 'https://gitlab.com/kampaay/auth0',
    },
    mails: {
      id: 25484489,
      url: 'https://gitlab.com/kampaay/transational-mails',
    },
    kampaai: {
      id: 56841636,
      url: 'https://gitlab.com/kampaay/kampaai',
    },
    SF: {
      id: 18831666,
      url: 'https://gitlab.com/kampaay/kampaay-storefront',
      domain: 'fe',
    },
    BO: {
      id: 18831682,
      url: 'https://gitlab.com/kampaay/kampaay-backoffice',
      domain: 'fe',
    },
    BE: {
      id: 18831632,
      url: 'https://gitlab.com/kampaay/kampaay-be',
      domain: 'be',
    },
  },
  teams: {
    sitter: {
      glLabel: 'Team Sitter',
      isActive: false,
    },
    private: {
      glLabel: 'Team Private',
      isActive: true,
    },
  },
  swas: {
    be: libor,
    fe: tommy,
  },
  sms: [nic, diphlo],
  coo: enri,
  cto: lore,
  pos: [ele, matti, marco],
  BErs: [libor, nic, diphlo, mauro],
  FErs: [tommy, vlad, francesco, andrea, stefano, davide],
  otherMembers: [gianmarco, giampaolo],
}

// TODO: move logic to a separate file. Just cfg should be in cfg.ts

export const allDevs = CFG.BErs.concat(CFG.FErs).concat(lore)
export const allActiveDevs = allDevs.filter((u) => u.isActive)
export const allUsers = [
  ...allDevs,
  ...CFG.pos,
  CFG.coo,
  CFG.cto,
  ...CFG.otherMembers,
]

export const allActiveUsers = allUsers.filter((u) => u.isActive)

export const getUserByUname = (username: string) =>
  allUsers.find((u) => u.glUsername === username)

export const getUserByGlId = (glId: number) =>
  allUsers.find((u) => u.glId === glId)

export const getDevByGlId = (glId: number) =>
  allDevs.find((u) => u.glId === glId)

export const isDevBe = (user: User) =>
  !!CFG.BErs.find((u) => u.glId === user.glId)

export const getProjectName = (projectId: number) => {
  const projCfg = entries(CFG.projects).find(
    ([_name, val]) => val.id === projectId,
  )
  return projCfg?.[0]
}
