<template>
  <VSonner :visible-toasts="9" />
</template>

<script setup lang="ts">
import { VSonner } from 'vuetify-sonner'
import { showUpdates } from './updates'

/**
 * NOTE: multiple toasts SHOULD be added in different ticks, otherwise they will not expand properly (bug to be opened)
 * ref: https://github.com/wobsoriano/vuetify-sonner
 */

onMounted(showUpdates)
</script>
