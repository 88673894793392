export abstract class WithDebounce {
  private queueMap: Record<string, ReturnType<typeof debouncedQueue>> = {}
  /**
   * execute an async action debouncing it and queueing it if necessary and
   * @param queueId
   * @param asyncAction
   * @param debounce
   * @returns
   */
  protected queue<T>(
    queueId: string,
    asyncAction: () => Promise<T>,
    debounce = true,
  ) {
    const queue = (this.queueMap[queueId] =
      this.queueMap[queueId] ?? debouncedQueue())
    return queue(asyncAction, !debounce)
  }
}
