let fetchingCount = 0
export const isFetching = ref(false)

export const registerCall = () => {
  fetchingCount++
  if (!isFetching.value) {
    isFetching.value = true
  }
}

export const unregisterCall = () => {
  fetchingCount--
  if (fetchingCount === 0) {
    isFetching.value = false
  }
}
