import { IssueBase } from './index'
import { LABELS } from '~/services/cfg'

export class Bug extends IssueBase {
  get weight(): number | undefined {
    return this._fromApi.weight ?? undefined
  }

  /**
   * indicates if an issue is BE only or FE only
   */
  get domain() {
    return this.labels.includes(LABELS.feOnly)
      ? 'fe'
      : this.labels.includes(LABELS.beOnly)
      ? 'be'
      : undefined
  }
}

export const BugR = reactifyClass(Bug)
