/**
 * interely imported from SF project
 */

import type { ThemeDefinition, VuetifyOptions } from 'vuetify'

const primary = {
  primary: '#f97B62',
  'primary-lighten-1': '#FFB4A5',
  'primary-lighten-2': '#FFDAD3',
  'primary-lighten-3': '#FFEDE9',
  'primary-lighten-4': '#FFFBFF',
  'primary-darken-1': '#E56C54',
  'primary-darken-2': '#C4543E',
  'primary-darken-3': '#A43C28',
  'primary-darken-4': '#842513',
  'primary-darken-5': '#640D01',
  'primary-darken-6': '#3E0400',
}

const primaryAccent = {
  primaryAccent: '#FF6320',
}

const secondary = {
  secondary: '#25427C',
  'secondary-lighten-1': '#365CA8',
  'secondary-lighten-2': '#5175C3',
  'secondary-lighten-3': '#6B8FDF',
  'secondary-lighten-4': '#86AAFC',
  'secondary-lighten-5': '#B0C6FF',
  'secondary-lighten-6': '#D9E2FF',
  'secondary-lighten-7': '#EDF0FF',
  'secondary-lighten-8': '#F9FAFF',
  'secondary-darken-1': '#002D6E',
  'secondary-darken-2': '#001944',
}

const secondaryAccent = {
  secondaryAccent: '#306DF1',
}

const neutral = {
  'neutral-darken-7': '#000000',
  'neutral-darken-6': '#212121',
  'neutral-darken-5': '#30323F',
  'neutral-darken-4': '#41434F',
  'neutral-darken-3': '#52545E',
  'neutral-darken-2': '#6B6D77',
  'neutral-darken-1': '#7E8089',
  neutral: '#919399',
  'neutral-lighten-1': '#B1B3BB',
  'neutral-lighten-2': '#CFD1DB',
  'neutral-lighten-3': '#E1E4EB',
  'neutral-lighten-4': '#F3F5FB',
  'neutral-lighten-5': '#FFFFFF',
}

const success = {
  'success-darken-4': '#004700',
  'success-darken-3': '#006000',
  'success-darken-2': '#00791E',
  'success-darken-1': '#2D9437',
  success: '#4CAF50',
  'success-lighten-1': '#69CB69',
  'success-lighten-2': '#85E783',
  'success-lighten-3': '#A2FF9E',
  'success-lighten-4': '#BEFFBA',
  'success-lighten-5': '#E4FDE4',
}

const warning = {
  'warning-darken-4': '#802700',
  'warning-darken-3': '#9D4000',
  'warning-darken-2': '#BB5900',
  'warning-darken-1': '#DB7200',
  warning: '#FB8C00',
  'warning-lighten-1': '#FFA72D',
  'warning-lighten-2': '#FFC24B',
  'warning-lighten-3': '#FFDF67',
  'warning-lighten-4': '#FFFB82',
  'warning-lighten-5': '#FFFFCC',
}

const error = {
  'error-darken-3': '#410002',
  'error-darken-2': '#690005',
  'error-darken-1': '#93000A',
  error: '#BA1A1A',
  'error-lighten-1': '#DE3730',
  'error-lighten-2': '#FF5449',
  'error-lighten-3': '#FF897D',
  'error-lighten-4': '#FFC6B9',
  'error-lighten-5': '#FFDFDB',
  'error-lighten-6': '#FFEDEA',
  'error-lighten-7': '#FFFBFF',
}

// https://www.figma.com/file/fy6tu3TXoEegqsVYzgadER/Platform?node-id=7266%3A109686
const colorPalette = {
  ...primary,
  ...primaryAccent,
  ...secondary,
  ...secondaryAccent,
  ...neutral,
  ...success,
  ...warning,
  ...error,
  green: success['success-lighten-5'],
  red: error['error-lighten-5'],
  yellow: warning['warning-lighten-5'],
  gray: neutral['neutral-lighten-3'],
}

export const kampaayTheme: ThemeDefinition = {
  dark: false,
  colors: colorPalette,
}

export const vuetifyTheme: VuetifyOptions['theme'] = {
  defaultTheme: 'kampaayTheme',
  themes: {
    kampaayTheme,
  },
}
