/**
 * this module allow to store and fetch data stringified in a gitlab issue description
 */

import { glUpdate } from '~/api/common'
import { getIssue } from '~/api/issues'
import { CFG } from '~/services/cfg'

export const buildGetData =
  <T>(parser: (data: string) => T) =>
  async (id: number) => {
    const data = await getIssue(CFG.projects.general.id, id)
    return parser(data.description ?? '')
  }

export const buildSetData =
  <T>(serializer: (data: T) => string) =>
  (id: number) =>
  async (data: T) => {
    const description = serializer(data)
    await glUpdate(CFG.projects.general.id.toString(), `issues/${id}`, {
      description,
    })
  }
