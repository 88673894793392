import { IssueBase } from '~/api/issues/models'
import { type DiscussionNoteR, discussionNoteR } from './DiscussionNote'
import { getUserDiscussion, replyToDiscussion, setDiscussionState } from '..'

class Discussion {
  public notes: DiscussionNoteR[]
  constructor(
    private fromApi: GLDiscussion,
    public parentIssue: IssueBase,
  ) {
    this.notes = fromApi.notes.map((note) => discussionNoteR(note, this))
  }
  get id() {
    return this.fromApi.id
  }
  get commentNotes() {
    return this.notes.filter((n) => n.isComment)
  }
  get firstNote() {
    return this.commentNotes.first()!
  }
  get lastNote() {
    return this.commentNotes.last()!
  }
  get stateChangeNotes() {
    return this.notes.filter((n) => !n.isComment)
  }
  get isOpen() {
    // if last state change is solved, then it is closed
    return !this.stateChangeNotes.last()?.isSolvedMark
  }
  get isPlanningDiscussion() {
    if (this.id === 2018584397) {
      debugger
    }
    return this.firstNote.isDiscussionStart
  }
  get author() {
    return this.firstNote.author
  }
  get webUrl() {
    return this.firstNote.webUrl
  }
  /**
   * all involved (tagged) users except the author
   */
  get involvedUsers() {
    return this.notes
      .flatMap((n) => n.taggedUsers)
      .distinct()
      .removeI(this.author)
  }
  async refreshNotes() {
    const disc = await getUserDiscussion(
      this.parentIssue.projectId,
      this.parentIssue.iid,
      this.id,
    )
    this.notes = disc.notes.map((note) => discussionNoteR(note, this))
  }
  async reply(body: string) {
    await replyToDiscussion(
      this.parentIssue.projectId,
      this.parentIssue.iid,
      this.id,
      body,
    )
    this.refreshNotes()
  }
  async setStatus(state: 'solved' | 'reopened') {
    await setDiscussionState(
      this.parentIssue.projectId,
      this.parentIssue.iid,
      this.id,
      state,
    )
    this.refreshNotes()
  }
  toggleStatus() {
    this.isOpen ? this.setStatus('solved') : this.setStatus('reopened')
  }
}

export const discussionR = reactifyClass(Discussion)
export type DiscussionR = Prettify<InstanceType<typeof Discussion>>
