import {
  getAllFiltered,
  glCreate,
  glDelete,
  glGet,
  glGetAll,
  glUpdate,
} from '~/api/common'
import { KAMPAAY_GROUP_ID, LABELS, isKScrumLabel } from '~/services/cfg'
import { spToHumanDuration } from '~/utils/dates'

// #region ********************** NEW API LOADERS

export const getIssue = async (projectId: number, issueIid: number) => {
  const res = await glGet<GLIssue>(projectId.toString(), `issues/${issueIid}`)
  return res.data
}

export const updateDescription = (
  projectId: number,
  issueIid: number,
  description: string,
) =>
  glUpdate<GLIssue>(projectId.toString(), `issues/${issueIid}`, {
    description,
  })

export const updateTitle = (
  projectId: number,
  issueIid: number,
  title: string,
) =>
  glUpdate<GLIssue>(projectId.toString(), `issues/${issueIid}`, {
    title,
  })

export const updateWeight = (
  projectId: number,
  issueIid: number,
  weight: number | null,
) =>
  glUpdate<GLIssue>(projectId.toString(), `issues/${issueIid}`, {
    weight,
  })

export const updateMilestone = (
  projectId: number,
  issueIid: number,
  milestoneId: number | null,
) =>
  glUpdate<GLIssue>(projectId.toString(), `issues/${issueIid}`, {
    milestone_id: milestoneId,
  })

export const updateLabels = (
  projectId: number,
  issueIid: number,
  labels: string[],
) =>
  glUpdate<GLIssue>(projectId.toString(), `issues/${issueIid}`, {
    labels: labels.join(','),
  })

export const updateAssignees = (
  projectId: number,
  issueIid: number,
  assigneeIds: number[],
) =>
  glUpdate<GLIssue>(projectId.toString(), `issues/${issueIid}`, {
    assignee_ids: assigneeIds,
  })

export const updateDueDate = (
  projectId: number,
  issueIid: number,
  dueDate: DayJs | null,
) =>
  glUpdate<GLIssue>(projectId.toString(), `issues/${issueIid}`, {
    due_date: dueDate ? dueDate.format('YYYY-MM-DD') : null,
  })

export const updateLabelsAndMilestone = (
  projectId: number,
  issueIid: number,
  labels: string[],
  milestoneId: number | null,
) =>
  glUpdate<GLIssue>(projectId.toString(), `issues/${issueIid}`, {
    labels: labels.join(','),
    milestone_id: milestoneId,
  })

/**
 * gets all entities mapped as issues (including special issues)
 * @param projectId
 * @param filter
 * @returns
 */
export const getIssueEntities = (projectId: number, filter?: GLFilter) =>
  getAllFiltered(
    (params) => glGetAll<GLIssue[]>(projectId.toString(), `issues`, params),
    filter,
  )

/**
 * gets all non-special issues
 * @param projectId
 * @param filter
 * @returns
 */
export const getIssues = (projectId: number, filter?: GLFilter) =>
  getIssueEntities(projectId, filter)
    // hides all special issues used to express kScrum entities:
    // whenever a new special issue is added, it should be labeled.
    .then((res) => res.filter((i) => !i.labels.find(isKScrumLabel)))

export const getWeightEvents = async (projectId: number, issueIid: number) =>
  getAllFiltered((params) => {
    return glGetAll<GLWeightEvent[]>(
      projectId.toString(),
      `issues/${issueIid}/resource_weight_events`,
      params,
    )
  })

// assignment and spent time
export const getNotes = (projectId: number, issueIid: number) =>
  glGetAll<GLNote[]>(projectId.toString(), `issues/${issueIid}/notes`)

export const getLabelEvents = (projectId: number, issueIid: number) =>
  getAllFiltered((params) => {
    return glGetAll<GLLabelEvent[]>(
      projectId.toString(),
      `issues/${issueIid}/resource_label_events`,
      params,
    )
  })

export const getTechDebts = (projectId: number) =>
  getIssues(projectId, {
    hasAllLabels: [LABELS.techDebt],
  })

// #endregion

const BASE_ISSUE_PATH = 'issues'

/**
 * TODO: remove or refactor duplicate `getIssues`
 * get the last 20 "opened" issues
 * assigned to a user
 *
 */
export const getLastOpenIssues = async (
  projectId: number,
  userId: number,
  milestone: string = '',
) => {
  const baseParams = {
    assignee_id: userId.toString(),
    state: 'opened',
  }

  const params = milestone
    ? {
        ...baseParams,
        milestone,
      }
    : baseParams

  const response = await glGet<GLIssue[]>(
    projectId.toString(),
    BASE_ISSUE_PATH,
    params,
  )

  return response.data
}

export const getEpicIssues = async (epicIid: number) =>
  glGetAll<GLIssue[]>(
    null,
    `groups/${KAMPAAY_GROUP_ID}/epics/${epicIid}/issues`,
  )

export const createIssue = async (
  projectId: number,
  title: string,
  opt?: {
    description?: string
    weight?: number
    labels?: string[]
    milestoneId?: number | null
  },
) =>
  await glCreate<GLIssue>(projectId.toString(), BASE_ISSUE_PATH, {
    title,
    description: opt?.description,
    weight: opt?.weight,
    labels: opt?.labels?.join(','),
    milestone_id: opt?.milestoneId,
  })

export const closeIssue = async (projectId: number, issueIid: number) =>
  await glUpdate<GLIssue>(
    projectId.toString(),
    `${BASE_ISSUE_PATH}/${issueIid}`,
    {
      state_event: 'close',
    },
  )

export const linkIssues = (
  srcProjectId: number,
  srcIid: number,
  targetProjectId: number,
  targetIid: number,
  linkType: GLLinkType = 'relates_to',
) =>
  glCreate<GLCreateLink>(srcProjectId.toString(), `issues/${srcIid}/links`, {
    target_project_id: targetProjectId,
    target_issue_iid: targetIid,
    link_type: linkType,
  })

export const deleteLink = (
  projectId: number,
  issueIid: number,
  linkId: number,
) => glDelete(projectId.toString(), `issues/${issueIid}/links/${linkId}`)

export const addSpent = async (
  issue: {
    projectId: number
    iid: number
  },
  spent: number,
) =>
  await glCreate<GLTimeLog>(
    issue.projectId.toString(),
    `issues/${issue.iid}/add_spent_time`,
    {
      duration: spToHumanDuration(spent),
    },
  )

export const isValidLabelEvent = (event: GLLabelEvent): event is LabelEvent =>
  'label' in event && event.label !== null

export const getIssueLinks = async (projectId: number, issueIid: number) => {
  const res = await getAllFiltered((params) => {
    return glGetAll<GLIssueLink[]>(
      projectId.toString(),
      `issues/${issueIid}/links`,
      params,
    )
  })
  return res
}
