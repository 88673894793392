import { fetchLoggedActivitiesBulk } from '~/api/user/models/Me'
import { fromApi } from '~/api/activities'

export const nonDevActivityTypes: Activity[] = [
  'dev test',
  'analysis',
  'extra',
  'scrum ceremonies',
  '1:1s',
  'R&D',
  'okrs',
  'sd',
  'MR review',
  'off',
  'bugfix',
  'SWA mgmt',
  'SM mgmt',
  'support',
]

export const getActivitiesTime = (activities: GLLoggedActivity[]) => {
  return nonDevActivityTypes.reduce(
    (acc, type) => ({
      ...acc,
      [type]: activities.filter((a) => a.type === type).sum((a) => a.time),
    }),
    {} as { [type in Activity]: number },
  )
}

class Dev {
  constructor(
    public glUsername: string,
    public glId: number,
    public emails: string[],
    public name: string,
    public slackId: string,
    public activityLogId: number,
    public isActive = true,
  ) {}

  get avatar_url() {
    return `https://gitlab.com/uploads/-/system/user/avatar/${this.glId}/avatar.png`
  }

  // #region activities
  // TODO: refactor and put common logic together with api/user/models/Me.ts
  // same semaphore could be used too!
  private _activities: GLDayActivityLog[] | undefined

  async loadActivities() {
    if (!this._activities) {
      this._activities = await fetchLoggedActivitiesBulk(this.activityLogId)
    }
    return this._activities
  }

  get activities() {
    // implicit side effect
    this.loadActivities()
    return this._activities?.map(fromApi)
  }

  getUserActivities(days: DayJs[]) {
    return days.map(
      (day) =>
        this.activities?.find((a) => a.date.isSame(day, 'day'))?.activities ??
        [],
    )
  }

  // #endregion
}

export const devR = reactifyClass(Dev)

export type TDev = InstanceType<typeof Dev>
