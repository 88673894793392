import { isDevBe } from '~/services/cfg'
import { type Sprint } from '../Sprint'
import { sprintsHub } from '../SprintsHub'

const SPRINTS_NUMBER_TO_CONSIDER = 3

const getUserDaysOff = (teamsCfg: TeamsCfg, glId: number) => {
  for (const team of Object.values(teamsCfg)) {
    for (const collection of ['BErs', 'FErs'] as const) {
      const dev = team[collection].find((dev) => dev.glId === glId)
      if (dev) return dev.daysOff
    }
  }
}
const getUserPresenceFactor = (sprint: Sprint, glId: number) => {
  if (!sprint.sprintDates || !sprint.teams) return undefined
  const daysOff = getUserDaysOff(sprint.teams, glId)
  return daysOff === undefined ? undefined : sprint.sprintDates.length - daysOff
}

class DevVelocity {
  constructor(
    public dev: Dev,
    private includeCurrentSprint: boolean,
  ) {}
  get domain() {
    return isDevBe(this.dev) ? 'be' : 'fe'
  }
  private get sprints() {
    const { closedSprints, currentSprint } = sprintsHub
    if (!closedSprints) return undefined

    const sprints = [...closedSprints].reverse()

    if (this.includeCurrentSprint) {
      if (!currentSprint) return undefined
      return [currentSprint, ...sprints]
    }
    return sprints
  }

  private get lastSprintsResults() {
    if (!this.sprints) return undefined
    const res: {
      pf: number
      delivered: number
      sprintNumber: number
    }[] = []
    for (const sprint of this.sprints) {
      if (res.length === SPRINTS_NUMBER_TO_CONSIDER) break

      const result = this.includeCurrentSprint
        ? sprint.currentOrSnapshotResults
        : sprint.resultsSnapshot
      const devResult = result?.individual[this.dev.glId]
      const pf = getUserPresenceFactor(sprint, this.dev.glId)

      if (!devResult || !pf) continue
      res.push({
        pf,
        delivered: devResult.delivered,
        sprintNumber: sprint.number,
      })
    }
    return res
  }

  private get avgDomainVelocity() {
    return this.includeCurrentSprint
      ? sprintsHub.avgVelocityIncludingCurrent[this.domain].velocity
      : sprintsHub.avgVelocity[this.domain].velocity
  }

  /**
   * average dev's velocity.
   * It is computed as the average of the velocity of his last <SPRINTS_NUMBER_TO_CONSIDER> sprints.
   * If the dev has less than <SPRINTS_NUMBER_TO_CONSIDER> sprints, the average is computed on the available sprints.
   * If the dev has no sprints, the average is computed on the domain's average velocity.
   */
  get velocity() {
    const { lastSprintsResults } = this

    // TODO: to be removed later, debugging
    console.log(
      `>>> Computed perf values for ${
        this.dev.name
      } (sprints ${lastSprintsResults?.map((x) => x.sprintNumber)}):`,
      lastSprintsResults
        ?.map((x) => `${x.delivered}/${x.pf} = ${x.delivered / x.pf}`)
        .join('\n'),
    )

    if (!lastSprintsResults?.length) return this.avgDomainVelocity
    const totPF = lastSprintsResults.sum((r) => r.pf)
    if (totPF === 0) return this.avgDomainVelocity

    const totDelivered = lastSprintsResults.sum((r) => r.delivered)
    return totDelivered / totPF
  }
}

export const DevVelocityR = reactifyClass(DevVelocity)
