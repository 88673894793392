import { glGetAll } from '~/api/common'
import { CFG, allDevs } from '~/services/cfg'
import { createMilestone, milestoneIsSprint, milestoneIsUnplanned } from '..'
import { Sprint, SprintR } from './Sprint'
import { sprintPayload } from './SprintsPayload'
import { issuesHub } from '~/api/issues/models/IssueHub'
import { toast } from 'vuetify-sonner'
import { createIssue } from '~/api/issues'
import { SprintIssueR, summaryToDescription } from '~/api/issues/models'
import { DevVelocityR } from './forecast/DevVelocity'
import { DomainVelocityR } from './forecast/DomainVelocity'

// sprints before this number have invalid data (no results, team composition with undefined ids, etc)
const FIRST_SPRINT_VISIBLE = 12

const loadMilestones = singletonFactory(async () => {
  const milestones = await glGetAll<GLMilestone[]>(
    CFG.projects.general.id.toString(),
    `milestones`,
  )
  return milestones
})

const loadSprints = singletonFactory(async () =>
  (await loadMilestones())
    .filter(
      (s) => milestoneIsSprint(s) && Number(s.title) >= FIRST_SPRINT_VISIBLE,
    )
    .map((m) => SprintR(m))
    .sort((a, b) => a.number - b.number),
)

const loadUnplanned = singletonFactory(async () =>
  (await loadMilestones())
    .filter(milestoneIsUnplanned)
    .map((m) => SprintR(m, true))
    .sort((a, b) => a.number - b.number),
)

/**
 * root node:
 * handles planning and sprints
 */
class SprintsHub {
  private _sprints?: Sprint[]
  private _unplanned?: Sprint[]

  async loadSprints() {
    const sprints = await loadSprints()
    if (!this._sprints) this._sprints = sprints
    return this._sprints
  }

  async loadUnplanned() {
    const unplanned = await loadUnplanned()
    if (!this._unplanned) this._unplanned = unplanned
    return this._unplanned
  }

  async createSprint(teams: TeamsCfg, sprintDates: DayJs[]) {
    if (sprintDates.length < 1) {
      toast.error('Sprint must have at least 1 day')
    }
    sprintDates = sprintDates.sort((a, b) => a.diff(b))

    const startDate = sprintDates[0]!
    const endDate = sprintDates[sprintDates.length - 1]!

    const [milestone] = await Promise.all([
      createMilestone({
        title: this.nextSprintNumber!.toString(),
        project_id: CFG.projects.general.id.toString(),
        start_date: startDate.toISOString().substring(0, 10),
        due_date: endDate!.toISOString().substring(0, 10),
      }),
      sprintPayload.saveSprintCfg(this.nextSprintNumber!, teams, sprintDates),
    ])
    const newSprint = SprintR(milestone)
    this._sprints!.push(newSprint)
  }

  get sprints() {
    // side effect implicit load
    this.loadSprints()
    return this._sprints?.sort((a, b) => a.number - b.number)
  }

  get unplanned() {
    // side effect implicit load
    this.loadUnplanned()
    return this._unplanned
  }

  get sprintsAndUnplanned() {
    const { sprints, unplanned } = this
    if (!sprints || !unplanned) return undefined
    return sprints.concat(unplanned)
  }

  /**
   * sprints with results
   */
  get closedSprints() {
    return this.sprints?.filter((s) => s.hasResults)
  }

  /**
   * sprints without results
   */
  get openSprints() {
    return this.sprints?.filter((s) => !s.hasResults)
  }

  get currentSprint() {
    return this.openSprints?.first()
  }
  /**
   * open sprints and unplanned excluding the current one
   */
  get planningSprints() {
    if (!!this.openSprints && !!this.currentSprint && !!this.unplanned) {
      return [...this.openSprints, ...this.unplanned].removeI(
        this.currentSprint,
      )
    }
  }

  get nextSprintNumber() {
    return this.sprints!.last()!.number + 1
  }

  get sprintsCount() {
    if (!!this._sprints) {
      return this._sprints.length
    }
  }

  /**
   * all issues in past sprints (closed sprints with results)
   */
  get pastIssues() {
    return this.closedSprints?.flatMap((s) => s.issues ?? [])
  }

  /**
   * all issues in the current sprint
   */
  get currentSprintIssues() {
    return this.currentSprint?.issues
  }

  /**
   * all issues in future sprints (open sprints and unplanned excluding the current one)
   */
  get futureIssues() {
    return this.planningSprints?.flatMap((s) => s.issues ?? [])
  }

  /**
   * all issues in all sprints
   */
  get allIssues() {
    if (!!this.pastIssues && !!this.futureIssues && !!this.currentSprintIssues)
      return [
        ...this.pastIssues,
        ...this.futureIssues,
        ...this.currentSprintIssues,
      ]
  }

  /**
   * when true, the forecast will include open sprints (current one) in stats
   */
  public includeCurrentSprintInForecast = false

  avgVelocity = {
    be: DomainVelocityR('be', false),
    fe: DomainVelocityR('fe', false),
  }

  avgVelocityIncludingCurrent = {
    be: DomainVelocityR('be', true),
    fe: DomainVelocityR('fe', true),
  }

  devVelocityNoCurrent = allDevs.map((dev) => DevVelocityR(dev, false))
  devVelocityIncludingCurrent = allDevs.map((dev) => DevVelocityR(dev, true))

  /**
   * devs velocity considering the includeCurrentSprintInForecast flag
   */
  get devVelocity() {
    return this.includeCurrentSprintInForecast
      ? this.devVelocityIncludingCurrent
      : this.devVelocityNoCurrent
  }

  /**
   * assigns an unassigned issue to a sprint
   * and takes care of updating "unassigned" and "sprint.issues" collections
   * @param issueIid
   * @param sprintNumber
   */
  async assignToSprint(issueIid: number, sprintNumber: number) {
    if (!this.sprintsAndUnplanned)
      throw new Error('Sprints or Unplanned not loaded')
    await issuesHub.loadUnassigned()
    if (!issuesHub.unassignedIssues)
      throw new Error('Unassigned issues not loaded')

    const targetSprint = this.sprintsAndUnplanned.find(
      (s) => s.number === sprintNumber,
    )
    if (!targetSprint || !targetSprint.issues)
      throw new Error('Sprint or Issues not loaded')

    const unassignedIssue = issuesHub.unassignedIssues.findByIid(issueIid)
    if (!unassignedIssue)
      throw new Error(`Unassigned Issue ${issueIid} not found`)

    await unassignedIssue.assignSprint(targetSprint)
    issuesHub.removeUnassigned(unassignedIssue)
    targetSprint.issues.push(unassignedIssue.toSprintIssue(targetSprint))
  }

  /**
   * assigns an already assigned issue to a different sprint and takes care of updating "sprint.issues" collections
   * @param issueIid
   * @param srcSprintNumber
   * @param targetSprintNumber
   */
  async updateSprintAssignment(
    issueIid: number,
    srcSprintNumber: number,
    targetSprintNumber: number,
  ) {
    if (!this.sprintsAndUnplanned)
      throw new Error('Sprints or Unplanned not loaded')
    const targetSprint = this.sprintsAndUnplanned.find(
      (s) => s.number === targetSprintNumber,
    )
    const srcSprint = this.sprintsAndUnplanned.find(
      (s) => s.number === srcSprintNumber,
    )
    if (
      !targetSprint ||
      !srcSprint ||
      !srcSprint.issues ||
      !targetSprint.issues
    )
      throw new Error('Sprint or Issues not loaded')
    // if issue is already assigned to a sprint, assign it to the new sprint,
    // remove it from the old sprint and add it to the new sprint collection
    const sprintIssue = srcSprint.issues.findByIid(issueIid)
    if (!sprintIssue)
      throw new Error(
        `Unexpected error: issue ${issueIid} not found in any sprint nor unassigned issues`,
      )
    await sprintIssue.assignSprint(targetSprint)
    targetSprint.issues.push(sprintIssue)
    srcSprint.issues.remove(sprintIssue)
  }

  /**
   *
   * @param issueIid
   */
  async unassignFromSprint(issueIid: number, sprintNumber: number) {
    if (!this.sprintsAndUnplanned)
      throw new Error('Sprints and Unplanned not loaded')
    const srcSprint = this.sprintsAndUnplanned.find(
      (s) => s.number === sprintNumber,
    )
    if (!srcSprint) throw new Error(`Sprint ${sprintNumber} not found`)
    const sprintIssues = srcSprint.issues
    if (!sprintIssues)
      throw new Error(`Issues not loaded for sprint ${sprintNumber}`)
    const sprintIssue = sprintIssues.findByIid(issueIid)
    if (!sprintIssue)
      throw new Error(`Issue ${issueIid} not found in sprint ${sprintNumber}`)
    const issue = await sprintIssue.unassignSprint()
    sprintIssue.sprint.issues!.remove(sprintIssue)
    issuesHub.addUnassigned(issue)
  }

  async addNewIssueToUnallocated(
    title: string,
    summary: string,
    teamName?: TeamName,
  ) {
    const unplanned = await this.loadUnplanned()
    const sprint = unplanned.last()!
    const issues = await sprint.loadIssues()
    const issue = await createIssue(CFG.projects.general.id, title, {
      milestoneId: sprint.id,
      description: summaryToDescription(summary),
      ...(teamName ? { labels: [CFG.teams[teamName].glLabel] } : {}),
    })
    const sprintIssue = SprintIssueR(issue, sprint)
    issues.push(sprintIssue)
    return sprintIssue
  }
}

export const sprintsHub = reactifyClass(SprintsHub)()
