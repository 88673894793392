/**
 * All routes should have trailing slashes.
 * If the user requests a route without a trailing slash, AWS will redirect to the same route with a trailing slash.
 */

export const routeMap = {
  dashboard: {
    path: '/',
    label: 'Dashboard',
  },
  burndown: {
    path: '/burndown/',
    label: 'Burndown',
  },

  seeds: {
    path: '/seeds/',
    label: 'Seeds',
  },

  planning: {
    path: '/planning/',
    label: 'Planning',
  },

  stats: {
    path: '/stats/',
    label: 'Stats',
  },

  sandbox: {
    path: '/sandbox/',
    label: 'Sandbox',
  },

  roadmap: {
    path: '/roadmap/',
    label: 'Roadmap',
  },

  about: {
    path: '/about/',
    label: 'About',
  },
} as const

export const guestRoutes = [routeMap.seeds] // first one is the home!
