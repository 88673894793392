/**
 * It implements the singleton pattern and should be used everywhere we need it.
 * It builds and returns two methods [singleton, force] wrapping a builder, given as a parameter.
 * - `singleton` method returns the builder-produced result or its cached value (when already produced)
 * - `force` method forces the builder to produce the result and caches it.
 *
 * When used to produce more than one result, the `cacheKeyGetter` parameter SHOULD be used to
 * provide a function that returns a unique key for each result.
 *
 * @param builder
 * @param cacheKeyGetter
 * @returns singleton callable with force method property
 */
export function singletonFactory<TArgs extends Array<any>, TOut, TContext>(
  builder: (this: TContext, ...args: TArgs) => TOut,
  cacheKeyGetter?: (...args: TArgs) => string | number,
) {
  const cache: Partial<Record<string, TOut>> = {}
  const resProduced: Partial<Record<string, boolean>> = {}
  const getCacheKey = (...args: TArgs) =>
    cacheKeyGetter ? cacheKeyGetter(...args) : '_'

  const force = function (this: TContext, ...args: TArgs) {
    const cacheKey = getCacheKey(...args)
    const instance = (cache[cacheKey] = builder.call(this, ...args))
    resProduced[cacheKey] = true
    return instance as TOut
  }

  const singleton = function (this: TContext, ...args: TArgs) {
    const cacheKey = getCacheKey(...args)
    return resProduced[cacheKey]
      ? (cache[cacheKey] as TOut)
      : force.call(this, ...args)
  }

  return Object.assign(singleton, { force })
}

/**
 * It implements a 'funnel' pattern that ensures that only one async task is executed at a time.
 * Producing a method through this function will create a closure.
 * Whenever produced method is called, the task is executed only if there is no other task of the same type running,
 * otherwise the promise of the already running task is returned.
 *
 * @param task a method that returns a promise that resolves to the task's result
 * @returns a method that returns a promise that resolves to the task's result
 */
export function funnelFactory<TArgs extends Array<any>, TOut, TContext>(
  task: (this: TContext, ...args: TArgs) => Promise<TOut>,
) {
  let promise: Promise<TOut> | undefined
  return function (this: TContext, ...args: TArgs) {
    if (promise === undefined) {
      promise = task.call(this, ...args)
    }
    promise.finally(() => {
      promise = undefined
    })
    return promise
  }
}

// JUST FOR TESTING PURPOSES: this should always be false!
// set it to true to test performances with and without the semaphore but always remember to set it back to false!
const bypassSemaphore = false

/**
 * Given a loader function returning a result of type Promise<TData>, it returns a method with the
 * same signature.
 * Under the hood it implements a 'batch' pattern that ensures parallel async loader tasks
 * would result in a synced promise resolution in order to improve performance of Vue reactivity.
 *
 * We exploit Vue inner reactivity mechanism:
 * When changing Vue component data the DOM is updated asynchronously.
 * Vue collects multiple updates to virtual DOM from all the components, then creates a single batch to update the DOM.
 * => Updating DOM in a single batch is more performant than doing multiple small updates! It results
 * in less DOM reflows and repaints and in less UI blocks.
 *
 * It can be used to batch loaders of a class whose instances are likely to load data parallely.
 */
export const semaphoreBuilder = () => {
  let parallelReqs = 0
  const callbacks: (() => void)[] = []

  const checkEndBatch = () => {
    if (callbacks.length === parallelReqs) {
      // last closes the door!
      console.log('>>> CLOSING parallel', parallelReqs, callbacks.length)
      callbacks.forEach((callback) => callback())
      parallelReqs = callbacks.length = 0
    }
  }

  return <TArgs extends any[], TData>(
    loader: (...args: TArgs) => Promise<TData>,
  ) => {
    if (bypassSemaphore) {
      return loader
    }

    return (...args: TArgs) => {
      return new Promise<TData>((resolve, reject) => {
        // console.log('>>> added parallel', parallelReqs.length, applyFns.length)
        const promise = loader(...args)
        parallelReqs++
        promise
          .then((data) => {
            callbacks.push(() => resolve(data))
            checkEndBatch()
          })
          .catch((e) => {
            callbacks.push(() => reject(e))
            checkEndBatch()
          })
      })
    }
  }
}

export const debounce = <T extends (...args: any[]) => ReturnType<T>>(
  callback: T,
  timeout: number = 500,
): ((...args: Parameters<T>) => void) => {
  let timer: ReturnType<typeof setTimeout>

  return (...args: Parameters<T>) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      callback(...args)
    }, timeout)
  }
}

/**
 * Debounced async chain factory
 *
 * When a procedure made by an async action and a reactive update is assumed
 * to be called multiple times in a short time, this function can be used to
 * debounce the calls and chain them to avoid multiple calls to the same action.
 *
 * It handles a queue of async actions that are initiated just in case no other
 * action follows in a short time. The last call overrides the previous ones and it is
 * the only one whose promise result is valued with the result of the async action.
 * The other calls are resolved with undefined.
 *
 * In this way, the consumer of the function can execute the reactive update just if
 * the promise is resolved with a value (that means no other calls are processing!).
 *
 * @param timeout
 * @returns
 */
export const debouncedQueue = (timeout = 800) => {
  let promise: Promise<unknown> = Promise.resolve()
  let chainedCount = 0 // current number of chained calls
  let abort: (() => void) | undefined

  return async <T>(
    asyncAction: () => Promise<T>,
    bypass = false,
  ): Promise<T | void> => {
    if (bypass) {
      return asyncAction()
    }

    // if there is a pending action, cancel it
    if (abort) {
      abort()
      abort = undefined
    }

    chainedCount++

    // chain the promise to wait for the previous action to complete or abord
    return ((promise as Promise<void | T>) = promise.then(
      () =>
        new Promise<void | T>((resolve, reject) => {
          // if other calls have been made in the meantime, just abort
          // without any action: last call overrides previous ones!
          if (--chainedCount) {
            resolve()
          } else {
            const timer = setTimeout(async () => {
              abort = undefined
              try {
                const res = await asyncAction()
                // if there are no other chained calls, resolve with the result
                // otherwise, resolve with undefined
                resolve(chainedCount ? undefined : res)
              } catch (error) {
                reject(error)
              }
            }, timeout)

            abort = () => {
              clearTimeout(timer)
              resolve()
            }
          }
        }),
    ))
  }
}
