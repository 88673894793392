import { CFG, allDevs } from '~/services/cfg'
import { glCreate } from '~/api/common'
import { buildGetData, buildSetData } from '~/api/common/dataHolder'
import dayjs from 'dayjs'

/**
 * use this to initialize log for new devs
 */
export const createLog = () => {
  Promise.all(
    allDevs.map((dev) =>
      glCreate<GLIssue>(CFG.projects.general.id.toString(), 'issues', {
        title: `log ${dev.glUsername}`,
      }).then((res) => ({
        res: { iid: res.iid },
        dev,
      })),
    ),
  ).then((r) => console.log('>>>>>>>>>>>>>> CREATED', r))
}

export const fromApi = (apiRes: GLDayActivityLog): DayActivityLog => ({
  date: dayjs(apiRes.date),
  activities: apiRes.activities,
})

const toApi = (dayActivityLog: DayActivityLog): GLDayActivityLog => ({
  date: dayActivityLog.date.toDateString(),
  activities: dayActivityLog.activities,
})

export const fetchLoggedActivities = buildGetData((data) => {
  try {
    return JSON.parse(data) as GLDayActivityLog[]
  } catch (error) {
    return []
  }
})

export const storeLoggedActivities = (id: number, data: DayActivityLog[]) =>
  buildSetData((data: DayActivityLog[]) => JSON.stringify(data.map(toApi)))(id)(
    data,
  )
