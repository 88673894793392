import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { createVuetify } from 'vuetify'
import { vuetifyTheme } from './2.vuetify/theme'

export default defineNuxtPlugin((nuxt) => {
  const vuetify = createVuetify({
    ssr: true,
    components,
    directives,
    theme: vuetifyTheme,
  })
  nuxt.vueApp.use(vuetify)
})
