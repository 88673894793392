const defaultDialog = {
  text: 'are you sure?',
}
type TDialog = typeof defaultDialog

export const dialogSetup = ref<TDialog | undefined>(undefined)

let resolvePromise: (value: boolean) => void | undefined

export const close = (answer: boolean) => {
  resolvePromise(answer)
  dialogSetup.value = undefined
}

export const getConfirmation = (dialog = defaultDialog) => {
  dialogSetup.value = dialog
  return new Promise<boolean>((resolve) => {
    resolvePromise = resolve
  })
}
