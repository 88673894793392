<template>
  <v-btn
    v-for="route in allowedRoutes"
    :disabled="route.isCurrent"
    @click="router.push(route.path)"
  >
    {{ route.label }}
  </v-btn>
</template>

<script setup lang="ts">
import { guestRoutes, routeMap } from '~/services/routing'
import { isGuest } from '~/plugins/3.guestManagement'

const router = useRouter()
defineComponent({ name: 'RoutingBtns' })

const currentRoute = useRoute()

const allowedRoutes = computed(() => {
  const allowed = isGuest.value ? guestRoutes : Object.values(routeMap)
  return allowed.map((r) => ({ ...r, isCurrent: r.path === currentRoute.path }))
})
</script>
