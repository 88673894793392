import { OAuth2Client, OAuth2Fetch } from '@badgateway/oauth2-client'
import { getGlClientId } from './runtimeCfg'
import { tokenStorage } from './storage'

let wrapper: OAuth2Fetch | undefined

export const getFetchWrapper = () => {
  if (!wrapper) {
    // console.log('>>>>>>>><<CLENT ID', getGlClientId(), getCfg().public)
    const client = new OAuth2Client({
      // grantType: 'authorization_code',
      // authenticationMethod: 'authorization_code',
      // The base URI of your OAuth2 server
      server: 'https://gitlab.com/',

      // OAuth2 client id
      // clientId:
      //     '2e06920be35aab1f8a36c3547b039ac9ae445cecb5b9bbb746b5edeedc64bc72',
      clientId: getGlClientId(),

      // OAuth2 client secret. Only required for 'client_credentials', 'password'
      // flows. You should not specify this for authorization_code.
      // clientSecret: '...',

      // The following URIs are all optional. If they are not specified, we will
      // attempt to discover them using the oauth2 discovery document.
      // If your server doesn't have support this, you may need to specify these.
      // you may use relative URIs for any of these.

      // Token endpoint. Most flows need this.
      // If not specified we'll use the information for the discovery document
      // first, and otherwise default to /token
      tokenEndpoint: '/oauth/token',

      // Authorization endpoint.
      //
      // You only need this to generate URLs for authorization_code flows.
      // If not specified we'll use the information for the discovery document
      // first, and otherwise default to /authorize
      authorizationEndpoint: '/oauth/authorize',

      // OAuth2 Metadata discovery endpoint.
      //
      // This document is used to determine various server features.
      // If not specified, we assume it's on /.well-known/oauth2-authorization-server
      // discoveryEndpoint: '/.well-known/oauth2-authorization-server',
      fetch: (...args) => fetch(...args),
    })

    const getToken = async () => {
      if (location.search?.includes('code=')) {
        // const codeVerifier = await generateCodeVerifier()

        const oauth2Token =
          await client.authorizationCode.getTokenFromCodeRedirect(
            document.location.href,
            {
              /**
               * The redirect URI is not actually used for any redirects, but MUST be the
               * same as what you passed earlier to "authorizationCode"
               */
              redirectUri: window.location.origin,

              /**
               * This is optional, but if it's passed then it also MUST be the same as
               * what you passed in the first step.
               *
               * If set, it will verify that the server sent the exact same state back.
               */
              // state: 'some-string',

              // codeVerifier,
            },
          )
        console.log('token', oauth2Token)
        window.history.pushState({}, document.title, window.location.pathname)

        return oauth2Token
        // const res = await fetchWrapper.fetch('https://gitlab.com/api/v4/user', {
        //     // headers: { Authorization: `Bearer ${oauth2Token.accessToken}` },
        // })
        // const user = await res.json()
        // console.log('user', user)
      } else {
        // try to get the token from storage
        const token = tokenStorage.get()
        if (token) return token

        // const codeVerifier = await generateCodeVerifier()
        document.location = await client.authorizationCode.getAuthorizeUri({
          // URL in the app that the user should get redirected to after authenticating
          redirectUri: window.location.origin,

          // Optional string that can be sent along to the auth server. This value will
          // be sent along with the redirect back to the app verbatim.
          // state: 'some-string',

          // codeVerifier,

          scope: ['api'],
        })
      }
    }

    wrapper = new OAuth2Fetch({
      client: client,

      /**
       * You are responsible for implementing this function.
       * it's purpose is to supply the 'initial' oauth2 token.
       */
      getNewToken: async () => {
        const token = await getToken()
        return token ?? null

        // // You can return null to fail the process. You may want to do this
        // // when a user needs to be redirected back to the authorization_code
        // // endpoints.
        // return null
      },

      /**
       * Optional. This will be called for any fatal authentication errors.
       */
      onError: (err) => {
        // err is of type Error
      },

      /**
       * This function is called whenever the active token changes. Using this is
       * optional, but it may be used to (for example) put the token in off-line
       * storage for later usage.
       */
      storeToken: tokenStorage.set,

      /**
       * Also an optional feature. Implement this if you want the wrapper to try a
       * stored token before attempting a full re-authentication.
       *
       * This function may be async. Return null if there was no token.
       */
      getStoredToken: tokenStorage.get,
    })
  }
  return wrapper
}
