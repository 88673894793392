import { TechIssue, TechIssueR } from '.'
import { getLinkedIssueData, getMr } from '~/api/mrs'
import { getIssue } from '..'

/**
 * merge requests generated by "resolve threads" feature in gitlab
 * can be recgnized by the following pattern in the description:
 * @example "The following discussion from !2243 should be addressed:"
 * where 2243 is the id of the MR that generated the tech debt
 */
const TECH_DEBT_MR_REGEX =
  /^The following discussions? from !([0-9]+)(\ \(merged\))? should be addressed:/

const loadRelatedIssue = singletonFactory(
  async (projectId: number, mrIid: number, tdIid: number) => {
    const mr = await getMr(projectId, mrIid)
    const { issueIid, projectId: mrProjectId } = getLinkedIssueData(mr)
    if (issueIid === undefined) return undefined
    if (mrProjectId !== projectId) {
      console.warn(
        `tech debt ${tdIid} is linked to issue ${issueIid} in project ${projectId} but the tech debt is in project ${projectId}`,
      )
      return undefined
    }

    try {
      const relatedTechIssue = await getIssue(projectId, issueIid)
      return TechIssueR(relatedTechIssue)
    } catch (error) {
      console.warn(
        `could not load tech debt #${issueIid} of project ${projectId} related to issue #${tdIid}`,
      )
      return undefined
    }
  },
  (projectId: number, mrIid: number, _tdIid: number) => `${projectId}-${mrIid}`,
)

const batchLoadRelatedIssue = semaphoreBuilder()(loadRelatedIssue)

export class TechDebt extends TechIssue {
  private relatedIssue?: TechIssue

  async loadRelatedIssue() {
    if (this.relatedIssue !== undefined || this.relatedMrIid === undefined)
      return
    const issue = await batchLoadRelatedIssue(
      this.projectId,
      this.relatedMrIid,
      this.iid,
    )
    this.relatedIssue = issue
    return issue
  }

  get relatedTechIssue() {
    // side effect implicit load
    this.loadRelatedIssue()
    return this.relatedIssue
  }

  get relatedMrIid() {
    const mrIid = this.description?.match(TECH_DEBT_MR_REGEX)?.[1]
    if (mrIid !== undefined) return Number(mrIid)
  }
}

export const TechDebtR = reactifyClass(TechDebt)
