import { type OAuth2Token } from '@badgateway/oauth2-client'

export const KEYS = {
  isGuest: 'isGuest',
  planningFilters: 'planningFilters',
  planningHiddenColumns: 'planningHiddenColumns',
  seedsFilters: 'seedsFilters',
  seedsHiddenColumns: 'seedsHiddenColumns',
  token: 'token-store',
  updatesIndex: 'lastUpdatesIndex',
  updatesIndexGuest: 'lastUpdatesIndexGuest',
  burnDownBugSummaryExpanded: 'burnDownBugSummaryExpanded',
  dashboardBugSummaryExpanded: 'dashboardBugSummaryExpanded',
} as const

/**
 * creates a ref that is stored and synchronized with local storage when updated
 * allowing for persistence of state across sessions
 * @param key
 * @param write
 * @param parse
 * @returns
 */
export const getStorageRef = <T>(
  key: keyof typeof KEYS,
  parse: (serialized: string | null) => T,
  write: (value: T) => string = (value) => JSON.stringify(value),
) => {
  const initialValue = localStorage.getItem(key)
  const r = ref<T>(parse(initialValue))
  watch(
    r,
    (value) => {
      localStorage.setItem(key, write(value as T))
    },
    { deep: true },
  )
  return r
}

/**
 * creates a ref that is stored and synchronized with local storage when updated
 * defaults to false (extend to allow a different default value)
 * @param key
 * @returns
 */
export const getStorageBoolRef = (key: keyof typeof KEYS) =>
  getStorageRef(key, (serialized) => serialized === 'true')

export const tokenStorage = {
  get: () => {
    const token = localStorage.getItem(KEYS.token)
    if (token) return JSON.parse(token) as OAuth2Token
    return null
  },
  set: (token: OAuth2Token) => {
    localStorage.setItem(KEYS.token, JSON.stringify(token))
  },
}

export const updatesStorage = {
  get: () => {
    const updates = localStorage.getItem(KEYS.updatesIndex)
    if (updates) return JSON.parse(updates) as number
    return -1
  },
  set: (updates: number) => {
    localStorage.setItem(KEYS.updatesIndex, JSON.stringify(updates))
  },
}

export const updatesStorageGuest = {
  get: () => {
    const updates = localStorage.getItem(KEYS.updatesIndexGuest)
    if (updates) return JSON.parse(updates) as number
    return -1
  },
  set: (updates: number) => {
    localStorage.setItem(KEYS.updatesIndexGuest, JSON.stringify(updates))
  },
}
