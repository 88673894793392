import { CFG, LABELS } from '~/services/cfg'
import {
  getIssueLinks,
  getLabelEvents,
  getNotes,
  getWeightEvents,
  updateLabels,
} from '..'
import { getUserDiscussions } from '~/api/discussions'

// API level caching
export const loadIssueLinks = singletonFactory(
  async (projectId: number, iid: number) => getIssueLinks(projectId, iid),
  (projectId: number, iid: number) => `${projectId}--${iid}`,
)

// model level caching
export const loadBlockedIssues = async (
  projectId: number,
  iid: number,
  force = false,
): Promise<GLIssueLink[]> => {
  const res = await (force ? loadIssueLinks.force : loadIssueLinks)(
    projectId,
    iid,
  )
  // const res = (await getIssueLinks(projectId, iid))
  return res.filter((i) => i.link_type === 'is_blocked_by')
}

export const loadTechIssues = (iid: number) =>
  loadIssueLinks(CFG.projects.general.id, iid).then((tis) =>
    tis.filter(
      (link) =>
        link.state !== 'closed' &&
        link.link_type === 'relates_to' &&
        link.project_id !== CFG.projects.general.id,
    ),
  )

export const loadSeeds = (iid: number, force = false) =>
  (force ? loadIssueLinks.force : loadIssueLinks)(
    CFG.projects.general.id,
    iid,
  ).then((tis) =>
    tis.filter(
      (link) =>
        link.state !== 'closed' &&
        link.link_type === 'relates_to' &&
        link.project_id === CFG.projects.general.id &&
        link.labels.includes(LABELS.seeds),
    ),
  )

const loadNotes = singletonFactory(
  async (projectId: number, iid: number) => getNotes(projectId, iid),
  (projectId: number, iid: number) => `${projectId}--${iid}`,
)

const loadDiscussions = singletonFactory(
  async (projectId: number, iid: number) => getUserDiscussions(projectId, iid),
  (projectId: number, iid: number) => `${projectId}--${iid}`,
)

// we use this semaphore for different fetches! This will improve performance
const loadIssueDetailsSemaphore = semaphoreBuilder()

export const batchLoadDiscussions = loadIssueDetailsSemaphore(
  (projectId: number, iid: number, force = false) =>
    (force ? loadDiscussions.force : loadDiscussions)(projectId, iid),
)

export const batchLoadNotes = loadIssueDetailsSemaphore(
  (projectId: number, iid: number, force = false) =>
    (force ? loadNotes.force : loadNotes)(projectId, iid),
)

const loadWeightEvents = singletonFactory(
  async (projectId: number, iid: number) => getWeightEvents(projectId, iid),
  (projectId: number, iid: number) => `${projectId}--${iid}`,
)

export const batchLoadWeightEvents = loadIssueDetailsSemaphore(
  (projectId: number, iid: number, force = false) =>
    (force ? loadWeightEvents.force : loadWeightEvents)(projectId, iid),
)

export const batchUpdateLabels = loadIssueDetailsSemaphore(
  (projectId: number, issueIid: number, labels: string[]) =>
    updateLabels(projectId, issueIid, labels),
)

const loadLabelEvents = singletonFactory(
  async (projectId: number, iid: number) => getLabelEvents(projectId, iid),
  (projectId: number, iid: number) => `${projectId}--${iid}`,
)

export const batchLoadLabelEvents = loadIssueDetailsSemaphore(loadLabelEvents)
