import {
  IssueBase,
  SprintIssueR,
  TechIssue,
  TechIssueR,
  loadTechIssues,
} from './index'
import {
  closeIssue,
  createIssue,
  linkIssues,
  updateDescription,
  updateLabels,
  updateMilestone,
} from '..'
import { CFG, LABELS } from '~/services/cfg'
import { Sprint } from '~/api/milestones/models/Sprint'
import { getUpdatedDescription } from '../mapping'

const SUMMARY_START = '# Summary'
const SUMMARY_END = '---'
const SUMMARY_REGEX = new RegExp(
  `^${SUMMARY_START}\n([\\s\\S]*?)\n\n${SUMMARY_END}`,
)

export const summaryToDescription = (summary: string) =>
  `${SUMMARY_START}\n${summary}\n\n${SUMMARY_END}`

const loadTechIssuesBatch = semaphoreBuilder()((iid: number) =>
  loadTechIssues(iid).then((tis) => tis.map(TechIssueR)),
)

const stats = reactifyClass(
  class {
    constructor(
      private issue: Issue,
      private domain?: Domain | 'all',
    ) {}
    get techIssues() {
      return this.domain !== 'all'
        ? this.issue.techIssues?.filter((ti) => ti.domain === this.domain)
        : this.issue.techIssues
    }

    get weight(): number {
      return this.techIssues?.sum((ti) => ti.weight ?? 0) ?? 0
    }
  },
)

/**
 * Issue represents a general issue (gitlab)
 */
export class Issue extends IssueBase {
  protected _techIssues?: TechIssue[]

  stats = {
    be: stats(this, 'be'),
    fe: stats(this, 'fe'),
    noDomain: stats(this),
    all: stats(this, 'all'),
  }

  async loadTechIssues() {
    const techIssues = await loadTechIssuesBatch(this.iid)
    if (!this._techIssues) this._techIssues = techIssues
    return this._techIssues
  }

  /**
   * sets the issue's and its tech issues' workflow state to 'TODO'
   * @param sprintId
   */
  async setAllAsTodo() {
    const labels = this.getUpdatedWorkflowLabels(LABELS.todo)
    if (!this._techIssues) {
      throw new Error(
        `No tech issues found for issue #${this.iid} ${this.title} (${this.webUrl})`,
      )
    }
    // move all tech issues to todo
    const tiInTodoPromise = Promise.all(
      this._techIssues.map((ti) =>
        ti.state === 'closed'
          ? Promise.resolve()
          : ti.updateWorkflowState(LABELS.todo),
      ),
    )
    // move the issue to todo and assign the sprint
    const updated = await updateLabels(
      CFG.projects.general.id,
      this.iid,
      labels,
    )
    this._fromApi.labels = updated.labels
    await tiInTodoPromise
  }

  async assignSprint(sprint?: Sprint) {
    const updated = await updateMilestone(
      CFG.projects.general.id,
      this.iid,
      sprint?.id ?? null,
    )
    this._fromApi.milestone = updated.milestone
  }

  get summary() {
    if (this._fromApi.description) {
      return this._fromApi.description.match(SUMMARY_REGEX)?.[1] ?? ''
    }
    return ''
  }

  async setSummary(summary: string, debounced = false) {
    const updated = await this.queue(
      'setSummary',
      () => {
        const wrapped = summaryToDescription(summary)
        const match = this._fromApi.description?.match(SUMMARY_REGEX)
        this._fromApi.description = match
          ? this._fromApi.description!.replace(match[0], wrapped)
          : `${wrapped}\n${this._fromApi.description}`

        return updateDescription(
          this.projectId,
          this.iid,
          this._fromApi.description ?? '',
        )
      },
      debounced,
    )
    if (updated) this._fromApi.description = updated.description
  }

  get techIssues() {
    // side effect implicit load
    this.loadTechIssues()
    return this._techIssues
  }

  async addTechIssue(
    projectId: number,
    title: string,
    tShirt?: number,
    weight?: number,
  ) {
    const description = getUpdatedDescription({ notes: '', tShirt })
    const issue = await createIssue(projectId, title, { description, weight })
    linkIssues(this.projectId, this.iid, projectId, issue.iid)
    const newTechIssue = TechIssueR(issue)
    this._techIssues?.push(newTechIssue)
    return newTechIssue
  }

  async closeTechIssue(techIssue: TechIssue) {
    await closeIssue(techIssue.projectId, techIssue.iid)
    this._techIssues?.removeBy((ti) => ti.iid === techIssue.iid)
  }

  /**
   * returns 'any' if no domain-specific tech issues are required,
   * returns 'fe' if no tech issues from BE are required and 'be' if
   * no tech issues from FE are required,
   * otherwise 'fs' is returned.
   */
  get domain() {
    if (this.techIssues?.every(({ domain }) => !domain)) return 'any'
    if (this.techIssues?.every(({ domain }) => domain !== 'fe')) return 'be'
    if (this.techIssues?.every(({ domain }) => domain !== 'be')) return 'fe'
    return 'fs'
  }

  get isRAndD() {
    return this.labels.includes(LABELS.RAndD)
  }

  toggleIsRAndD() {
    return this.persistLabels(this.labels.toggleI(LABELS.RAndD))
  }

  get isReleased() {
    return this.state === 'closed' || this.isOverToDate()
  }

  get weight() {
    return this.stats.all.weight
  }

  get hasTechIssues() {
    return this.techIssues && this.techIssues.length > 0
  }

  get budgetTechIssues() {
    return this.techIssues?.filter((ti) => ti.isBudget) ?? []
  }

  get hasBudgetTechIssues() {
    return this.budgetTechIssues.length > 0
  }

  get inaccuracy() {
    // biggest wins (size matters).
    if (this.labels.includes(LABELS.missingTest3)) return 3
    if (this.labels.includes(LABELS.missingTest2)) return 2
    if (this.labels.includes(LABELS.missingTest1)) return 1
    return 0
  }

  get techDebts() {
    return this.techIssues?.flatMap((ti) => ti.techDebts) ?? []
  }

  toSprintIssue(sprintBelongingTo: Sprint) {
    return SprintIssueR(this._fromApi, sprintBelongingTo)
  }
}

export const issueR = reactifyClass(Issue)
export type IssueR = Prettify<InstanceType<typeof Issue>>
