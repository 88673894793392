<template>
  <v-tooltip v-if="tooltip" :text="tooltip">
    <template v-slot:activator="{ props }">
      <span v-bind="props">
        <slot />
      </span>
    </template>
  </v-tooltip>
  <slot v-else />
</template>

<script setup lang="ts">
type Props = {
  tooltip?: string
}

const props = defineProps<Props>()

const { tooltip } = toRefs(props)
</script>
