import { glGet } from '~/api/common'
import { CFG } from '~/services/cfg'
import { type MergeRequestScope } from '~/api/mrs/types'

export const getMr = async (projectId: number, mrIid: number) => {
  const res = await glGet<GLMR>(projectId.toString(), `merge_requests/${mrIid}`)
  return res.data
}

const BASE_MR_PATH = 'merge_requests'
const buildMergeRequestParams = (
  scope: MergeRequestScope,
  userId: number,
): Record<string, string> => {
  switch (scope) {
    case 'created-by-me':
      return {
        ['author_id']: userId.toString(),
      }
    case 'to-review':
      return {
        ['reviewer_id']: userId.toString(),
      }
    default:
      throw new Error(`unknown merge request scope: ${scope}`)
  }
}
export const getLastMergeRequests = async (
  projectId: number,
  userId: number,
  scope: MergeRequestScope = 'created-by-me',
) => {
  const res = await glGet<GLMR[]>(
    projectId.toString(),
    `${BASE_MR_PATH}`,
    buildMergeRequestParams(scope, userId),
  )
  return res.data
}

const issueLinkIdMatcher = /(?<=(Related to|closes) #)\d+/gm
const issueLinkUrlMatcher =
  /(?:Related to |closes )?(https:\/\/gitlab.com\/kampaay\/[^\/]+)\/-\/issues\/(\d+)/gm

export const getLinkedIssueData = (mr: GLMR) => {
  let projectId = mr.project_id
  let issueIid = mr.description?.match(issueLinkIdMatcher)?.[0]
  if (!issueIid && mr.description) {
    const match = issueLinkUrlMatcher.exec(mr.description)
    if (match) {
      const [_, projectUrl, matchedIssueIid] = match
      const proj = Object.values(CFG.projects).find((p) => p.url === projectUrl)
      if (proj) {
        projectId = proj.id
        issueIid = matchedIssueIid
      }
    }
  }
  return {
    issueIid: issueIid !== undefined ? Number(issueIid) : undefined,
    projectId,
  }
}
