/**
 * this merges 2 objects ensuring that there are no overlapping keys
 * so that an extension does not accidentally override any other extension's existing methods
 * @param a
 * @param b
 * @returns
 */
export const mergeNonOverlapping = <
  T1,
  T2 extends { [K in keyof T2]: K extends keyof T1 ? never : T2[K] },
>(
  a: T1,
  b: T2,
): T1 & T2 => ({
  ...a,
  ...b,
})
