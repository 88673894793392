import { SprintIssue, TechIssue } from './index'
import { Sprint } from '~/api/milestones/models/Sprint'
import { type ISprintIssueBase } from '.'
import { addSpent } from '..'
import { LABELS } from '~/services/cfg'

export class SprintTechIssue extends TechIssue implements ISprintIssueBase {
  constructor(
    protected _fromApi: GLIssue,
    protected sprint: Sprint,
  ) {
    super(_fromApi)
  }

  /**
   * general related issue
   */
  get relatedIssue() {
    const issue = this.sprint.issues?.find(
      (i) => i.techIssues?.find((ti) => ti.iid === this.iid),
    )
    if (!issue)
      throw new Error(
        `General Issue associated with techIssue ${this.webUrl} not found in sprint ${this.sprint.number}: make sure issues are loaded before instantiating SprintTechIssue!`,
      )
    return issue
  }

  get isFastTrack() {
    return this.labels.includes(LABELS.fastTrack)
  }

  get sprintStartWeight() {
    return (
      this.startWeight ??
      this.weightEvents
        ?.filter((e) => e.createdAt.isBefore(this.sprint.firstDay!, 'day'))
        .last()?.weight ??
      0
    )
  }

  get progress() {
    // TODO: move and inherit in SprintTechIssue and SprintIssue ?
    return SprintIssue.getProgressStatus(
      this.sprintStartWeight,
      this.sprintSpentSP,
      this.weight ?? 0,
    )
  }

  get dailyUpdates() {
    return this.sprint.sprintDaysTillNow
      ?.map((d, i) => {
        const [dayStart, dayEnd] = this.sprint.dayBoundaries![i]!
        const getDailyEvents = <T extends { createdAt: DayJs }>(events?: T[]) =>
          events?.filter((e) => e.createdAt.isBetween(dayStart, dayEnd)) ?? []

        const weightEvents = getDailyEvents(this.weightEvents)
        const spentEvents = getDailyEvents(this.spentTimeEvents)
        const assignmentEvents = getDailyEvents(this.assignmentEvents)
        const spent = spentEvents.sum((e) => e.spent)
        const weight =
          this.weightEvents?.filter((e) => !e.createdAt.isAfter(dayEnd)).last()
            ?.weight ?? this.sprintStartWeight
        const assignment = assignmentEvents.last()?.assignee
        return {
          weightEvents,
          spentEvents,
          assignmentEvents,
          weight,
          spent,
          assignment,
        }
      })
      .map((daily, i, arr) => {
        const prev = arr[i - 1]
        const delta = prev ? daily.weight - prev.weight : 0
        return { ...daily, delta }
      })
  }

  get sprintWeightEvents() {
    return this.dailyUpdates?.flatMap((u) => u.weightEvents)
  }

  get sprintSpentEvents() {
    return this.dailyUpdates?.flatMap((u) => u.spentEvents) ?? []
  }

  get sprintSpentSP() {
    return this.dailyUpdates?.sum((u) => u.spent) ?? 0
  }

  get deliveredWeight() {
    if (this.isBudget) {
      return this.sprintSpentSP
    }
    return this.sprintStartWeight - this.techDebtsWeight
  }

  get deltaSpent() {
    return this.sprintSpentSP - this.sprintStartWeight
  }

  get overEstimate() {
    return Math.max(this.deltaSpent, 0)
  }

  get underEstimate() {
    return Math.max(-this.deltaSpent, 0)
  }

  get eta() {
    const velocities = this.sprint.velocityForecastAggregates
    if (
      velocities &&
      this.teamName &&
      this.domain &&
      this.sprint.missingSprintDays
    ) {
      const velocity = velocities[this.teamName][this.domain]
      const weight = this.weight ?? 0
      if (weight > 0) {
        const missingDays = Math.round(weight / velocity)
        return this.sprint.missingSprintDays[missingDays]
      }
      const lastCompleteEvent = this.sprintWeightEvents
        ?.filter((e) => e.weight === 0 || e.weight === null)
        .last()
      if (lastCompleteEvent) {
        return lastCompleteEvent.createdAt.startOf('day')
      }
      return this.sprint.firstDay
    }
  }

  async addSpentTime(time: number) {
    await addSpent(this, time)
    await this.loadNotes(true)
  }

  async setEstimate(weight?: number, debounced = false) {
    await super.setEstimate(weight, debounced)
    await this.loadWeightEvents(true)
  }
}

export const SprintTechIssueR = reactifyClass(SprintTechIssue)
