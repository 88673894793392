/**
 * use this forced casting to keep typing consistency when assigning
 * a ref to a member of a class instance.
 * This is needed when class is reactified since vue unwraps all nested refs
 * https://vuejs.org/api/reactivity-core.html#ref
 *
 * @example
 * class Foo {
 *  bar: number
 *  constructor(bar: Ref<number>, private baz: string) {
 *   this.bar = unwrap(bar)
 *  }
 * }
 *
 * @param ref
 * @returns
 */
export const unwrap = <T>(ref: Ref<T>): T => ref as unknown as T

/**
 * recursively get all property descriptors of an object
 * @param obj
 */
const getDescriptors = <T extends {}>(
  obj: T,
): ReturnType<typeof Object.getOwnPropertyDescriptors> => {
  const parent = Object.getPrototypeOf(obj)
  if (parent !== null) {
    return {
      ...getDescriptors(parent),
      ...Object.getOwnPropertyDescriptors(obj.constructor.prototype),
    }
  } else {
    return {}
  }
}

/**
 * converts a class instance to a reactive object and transforms all
 * getters to computed properties without modifying the obj interface
 */
export const reactify = <T extends {}>(obj: T) => {
  const objRef = ref(obj)
  const descs = getDescriptors(obj)

  Object.entries(descs).forEach(([propName, { get, set }]) => {
    if (!['this', 'constructor'].includes(propName) && !!get) {
      const comp = computed(() => get.call(objRef.value))
      Object.defineProperty(objRef.value, propName, {
        get() {
          return comp.value
        },
        set,
      })
    }
  })
  return objRef.value as T
}

/**
 * Converts a class constructor to a reactive factory function that
 * enables vue reactivity and optimizes getters performance.
 *
 * **IMPORTANT**: If you need to assign a ref to a class member, use the
 * {@link unwrap} function, otherwise it will result in a typing mismatch!
 */
export const reactifyClass =
  <T extends {}, TParams extends any[]>(ctor: new (...params: TParams) => T) =>
  (...params: TParams) =>
    reactify(new ctor(...params))
