import { default as about4jzp6jXN5tMeta } from "/builds/kampaay/scrum/pages/about.vue?macro=true";
import { default as burndownRW2r245pj4Meta } from "/builds/kampaay/scrum/pages/burndown.vue?macro=true";
import { default as index0qkFhTKX0NMeta } from "/builds/kampaay/scrum/pages/index.vue?macro=true";
import { default as planningM2UynJ3slJMeta } from "/builds/kampaay/scrum/pages/planning.vue?macro=true";
import { default as roadmapvqBvXGbWMaMeta } from "/builds/kampaay/scrum/pages/roadmap.vue?macro=true";
import { default as sandbox3AKjq3iNRJMeta } from "/builds/kampaay/scrum/pages/sandbox.vue?macro=true";
import { default as seedsgYO5nZ57cWMeta } from "/builds/kampaay/scrum/pages/seeds.vue?macro=true";
import { default as stats9MeRQoLWBFMeta } from "/builds/kampaay/scrum/pages/stats.vue?macro=true";
export default [
  {
    name: about4jzp6jXN5tMeta?.name ?? "about",
    path: about4jzp6jXN5tMeta?.path ?? "/about",
    meta: about4jzp6jXN5tMeta || {},
    alias: about4jzp6jXN5tMeta?.alias || [],
    redirect: about4jzp6jXN5tMeta?.redirect || undefined,
    component: () => import("/builds/kampaay/scrum/pages/about.vue").then(m => m.default || m)
  },
  {
    name: burndownRW2r245pj4Meta?.name ?? "burndown",
    path: burndownRW2r245pj4Meta?.path ?? "/burndown",
    meta: burndownRW2r245pj4Meta || {},
    alias: burndownRW2r245pj4Meta?.alias || [],
    redirect: burndownRW2r245pj4Meta?.redirect || undefined,
    component: () => import("/builds/kampaay/scrum/pages/burndown.vue").then(m => m.default || m)
  },
  {
    name: index0qkFhTKX0NMeta?.name ?? "index",
    path: index0qkFhTKX0NMeta?.path ?? "/",
    meta: index0qkFhTKX0NMeta || {},
    alias: index0qkFhTKX0NMeta?.alias || [],
    redirect: index0qkFhTKX0NMeta?.redirect || undefined,
    component: () => import("/builds/kampaay/scrum/pages/index.vue").then(m => m.default || m)
  },
  {
    name: planningM2UynJ3slJMeta?.name ?? "planning",
    path: planningM2UynJ3slJMeta?.path ?? "/planning",
    meta: planningM2UynJ3slJMeta || {},
    alias: planningM2UynJ3slJMeta?.alias || [],
    redirect: planningM2UynJ3slJMeta?.redirect || undefined,
    component: () => import("/builds/kampaay/scrum/pages/planning.vue").then(m => m.default || m)
  },
  {
    name: roadmapvqBvXGbWMaMeta?.name ?? "roadmap",
    path: roadmapvqBvXGbWMaMeta?.path ?? "/roadmap",
    meta: roadmapvqBvXGbWMaMeta || {},
    alias: roadmapvqBvXGbWMaMeta?.alias || [],
    redirect: roadmapvqBvXGbWMaMeta?.redirect || undefined,
    component: () => import("/builds/kampaay/scrum/pages/roadmap.vue").then(m => m.default || m)
  },
  {
    name: sandbox3AKjq3iNRJMeta?.name ?? "sandbox",
    path: sandbox3AKjq3iNRJMeta?.path ?? "/sandbox",
    meta: sandbox3AKjq3iNRJMeta || {},
    alias: sandbox3AKjq3iNRJMeta?.alias || [],
    redirect: sandbox3AKjq3iNRJMeta?.redirect || undefined,
    component: () => import("/builds/kampaay/scrum/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: seedsgYO5nZ57cWMeta?.name ?? "seeds",
    path: seedsgYO5nZ57cWMeta?.path ?? "/seeds",
    meta: seedsgYO5nZ57cWMeta || {},
    alias: seedsgYO5nZ57cWMeta?.alias || [],
    redirect: seedsgYO5nZ57cWMeta?.redirect || undefined,
    component: () => import("/builds/kampaay/scrum/pages/seeds.vue").then(m => m.default || m)
  },
  {
    name: stats9MeRQoLWBFMeta?.name ?? "stats",
    path: stats9MeRQoLWBFMeta?.path ?? "/stats",
    meta: stats9MeRQoLWBFMeta || {},
    alias: stats9MeRQoLWBFMeta?.alias || [],
    redirect: stats9MeRQoLWBFMeta?.redirect || undefined,
    component: () => import("/builds/kampaay/scrum/pages/stats.vue").then(m => m.default || m)
  }
]