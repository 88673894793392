import { LABELS } from '~/services/cfg'

const CUSTOM_DATA_ENCODING_REGEX =
  /<details>\s*?<summary>\s*?kScrum data - autogenerated - DO NOT DELETE\s*<\/summary>\s*(((?!<\/details>).)*)\s*<\/details>/

export const PLANNING_WF_STATE_LABELS = [
  LABELS.blocked,
  LABELS.defined,
  LABELS.exploration,
  LABELS.roadmapReady,
  LABELS.design,
  LABELS.analysis,
] as const

export const SCRUM_WF_STATE_LABELS = [
  LABELS.workable,
  LABELS.todo,
  LABELS.wip,
  LABELS.codeReview,
  LABELS.inStaging,
  LABELS.review,
  LABELS.readyForStaging,
  LABELS.readyForProd,
] as const

export const WORKFLOW_STATE_LABELS = [
  ...PLANNING_WF_STATE_LABELS,
  ...SCRUM_WF_STATE_LABELS,
] as const

export type PlanningWfState = (typeof PLANNING_WF_STATE_LABELS)[number]
export type WorkflowState = (typeof WORKFLOW_STATE_LABELS)[number]

const encodeCustomData = <T extends object>(data: T): string =>
  `<details><summary>kScrum data - autogenerated - DO NOT DELETE</summary>${JSON.stringify(
    data,
  )}</details>`

/**
 * given a description (possibly having custom data), returns a new description
 * with new data encoded
 * @param desc
 * @param data
 * @returns
 */
export const getUpdatedDescription = <T extends object>(
  data: T,
  desc?: string,
): string => {
  desc = desc ?? ''
  const match = CUSTOM_DATA_ENCODING_REGEX.exec(desc)?.[1]
  const encodedData = encodeCustomData(data)
  if (match) {
    return desc.replace(CUSTOM_DATA_ENCODING_REGEX, encodedData)
  } else {
    return `${desc}\n\n${encodedData}`
  }
}

export const extractIssueDataFromDesc = <T extends object>(
  getDefault: () => T,
  desc?: string,
): T => {
  if (desc) {
    const match = CUSTOM_DATA_ENCODING_REGEX.exec(desc)?.[1]
    if (match) {
      try {
        return JSON.parse(match) as T
      } catch (error) {
        console.error(error)
        return getDefault()
      }
    }
  }
  return getDefault()
}

export const findWorkflowStateLabel = (
  labels: string[],
): WorkflowState | undefined => {
  const label = labels.find((label) =>
    WORKFLOW_STATE_LABELS.includes(label as WorkflowState),
  )

  return label as WorkflowState | undefined
}
