import { type RuntimeConfig } from 'nuxt/schema'

const cfg = ref<RuntimeConfig | undefined>(undefined)

export const getCfg = () => {
  if (!cfg.value) {
    throw new Error(
      'Unexpected: cfg not loaded. Did you forget to call useLoadRuntimeCfg before?',
    )
  }
  return cfg.value
}

export const useLoadRuntimeCfg = () => {
  cfg.value = useRuntimeConfig()
}

export const getBaseUrl = () => getCfg().public.PUBLIC_BASE_URL as string

export const getApiBasePath = () => getCfg().public.PUBLIC_API_V4_URL as string

export const getGlClientId = () => getCfg().public.PUBLIC_CLIENT_ID as string

export const getGroupAccessToken = () =>
  getCfg().public.PUBLIC_GROUP_ACCESS_TOKEN as string
