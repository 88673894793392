import { updatesStorage, updatesStorageGuest } from '~/services/storage'
import { toast } from 'vuetify-sonner'
import { isGuest } from '~/plugins/3.guestManagement'

type Update = {
  title: string
  messages: string[]
}

const getUpdateFolder = (isGuest: boolean) =>
  isGuest ? '/updatesGuest' : '/updates'

const fetchUpdate = async (id: number, isGuest: boolean) => {
  const res = await fetch(`${getUpdateFolder(isGuest)}/${id}.json`)
  const data = (await res.json()) as Update
  return data
}

const fetchUpdatesIndex = (isGuest: boolean) =>
  fetch(`${getUpdateFolder(isGuest)}/index.json`).then(
    (res) => res.json() as Promise<number>,
  )

export const showUpdates = async () => {
  const lastIndexStorage = isGuest.value ? updatesStorageGuest : updatesStorage
  const updatesIndex = await fetchUpdatesIndex(!!isGuest.value)
  console.log('updatesIndex', updatesIndex)
  let lastReadMessageIndex = lastIndexStorage.get()
  console.log('lastMessageIndex', lastReadMessageIndex)
  if (lastReadMessageIndex < updatesIndex) {
    // take maximum 3 updates from the most recent (never under 0)
    await Promise.all(
      Array(Math.min(3, updatesIndex - lastReadMessageIndex))
        .fill(0)
        .map(async (_, i) => {
          const indexToFetch = updatesIndex - i
          try {
            const update = await fetchUpdate(indexToFetch, !!isGuest.value)
            const toastId = toast.info(update.title, {
              // description: update.messages.join('<br/>'),
              description: `<ul class="ml-5" style="min-width: 350px">${update.messages
                .map((m) => `<li>${m}</li>`)
                .join('')}</ul>`,
              duration: Number.POSITIVE_INFINITY,
              action: {
                label: 'Got it!',
                onClick: () => {
                  // closing the toast will set all shown messages as read
                  if (lastReadMessageIndex < updatesIndex) {
                    lastReadMessageIndex = updatesIndex
                    lastIndexStorage.set(updatesIndex)
                  }
                },
                buttonProps: {
                  // v-btn props
                },
              },
            })
          } catch (e) {
            // we don't want a fatal error here!
            console.error('unexpected error while fetching update')
            console.error(e)
          }
        }),
    )
  }
}
