import { glCreate, glGet } from '~/api/common'

export const UNPLANNED_START_COUNT = 1000

export const milestoneIsSprint = (milestone: GLMilestone) => {
  const num = Number(milestone.title)
  return !isNaN(num) && num < UNPLANNED_START_COUNT
}

export const milestoneIsUnplanned = (milestone: GLMilestone) => {
  const num = Number(milestone.title)
  return !isNaN(num) && num >= UNPLANNED_START_COUNT
}

export const createMilestone = (milestone: GLUpsertMilestone) =>
  glCreate<GLMilestone>(milestone.project_id, `milestones`, milestone)

export const getMilestones = async (projectId: number) => {
  const res = await glGet<GLMilestone[]>(projectId.toString(), `milestones`)
  return res.data
}
